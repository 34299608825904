import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
    getHandlerTableChange,
    sortCaret,
    headerSortingClasses,
  } from "../../../_metronic/_helpers";
  import { Pagination } from "../../../_metronic/_partials/controls";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import SVG from "react-inlinesvg";
import { TextField, Grid } from "@material-ui/core";
import { ToastContainer, toast, Slide } from "react-toastify";

/* ========= react-redux ========= */
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchAppAvailedCoupons } from "../../pages/Applications/_redux/organizationsActions";
import { Redirect } from "react-router-dom";
import { useHistory,useParams } from "react-router-dom";
/* ========= style for form ========= */
import "../../../styles/applicationTable.scss";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

function AvailedCoupons({  ...props }) {
const {id}= useParams();
 
 useEffect(() => {
     props.fetchAppAvailedCoupons(id);
    }, []);

    const customHeaderTemplate = (
      column,
      colIndex,
      { sortElement, filterElement }
    ) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ height: "20px" }}>
            <span>
              {column.text} {sortElement}
            </span>
          </div>
          <div>{filterElement}</div>
        </div>
      );
    };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerFormatter: customHeaderTemplate,
      // sortCaret: sortCaret,
      // headerSortingClasses,
       filter: textFilter(),
    },

    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerFormatter: customHeaderTemplate,
      filter: textFilter(),
    },
    {
      dataField: "mobile",
      text: "Phone Number",
      sort: true,
      headerFormatter: customHeaderTemplate,
      filter: textFilter(),
    },
    {
      dataField: "redeemedDate",
      text: "Redeemed Date",
      sort: true,
      headerFormatter: customHeaderTemplate,
      filter: textFilter(),
      formatter: (cell) => {
        return (
          <>
            {moment(cell).format("LL")}
            <br />
            {moment(cell).format("hh:mm A")}
          </>
        );
      },
    },
     ];
  // Table pagination properties
  const PaginationOptions={
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(PaginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            // <Pagination
            //   //isLoading={listLoading}
            //   paginationProps={paginationProps}
            // >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-align-top-left cursor"
                bootstrap4
                // remote
                keyField="id"
                hover={true}
                data={props.appAvailedCoupon === null ? [] : props.appAvailedCoupon}
                columns={columns}
                noDataIndication={<div className='text-center'>No Redeemed Coupon Found</div>}
                //defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange()
                //applicationsUIProps.setQueryParams
                }
                filter={filterFactory()}
                {...paginationTableProps}
              >
                {/*<PleaseWaitMessage entities={entities} />*/}
                {/*<NoRecordsFoundMessage entities={entities} />*/}
              </BootstrapTable>
          );
        }}
      </PaginationProvider>
    </>
  );
}
const mapStateToProps = (state) => ({
  error: state.applications.error,
  success: state.applications.success,
  loading: state.applications.loading,
  appAvailedCoupon: state.applications.appAvailedCoupon,
  
});

const mapActionsToProps = {
    fetchAppAvailedCoupons,
};
export default connect(mapStateToProps, mapActionsToProps)(AvailedCoupons);
