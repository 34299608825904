import React, { useEffect, useState } from "react";
import {
  LineChart,
  AreaChart,
  Area,
  Line,
  Brush,
  XAxis,
  Legend,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { fetchInsightsGraph } from '../../pages/Applications/_redux/organizationsActions';

const ImpressionsChart = ({ auth, ...props }) => {
  const {id} = useParams();
  console.log("appiidd",id)
  const startdate = useSelector((state) => state.applications.startDate);
  const enddate = useSelector((state) => state.applications.endDate);
  const conversions = props?.insightsGraph?.map((list, i) => {
    return {
      "lead": (list?.actions!=null)?list?.actions[0].value:0,
      "impressions": list.impressions,
      "reach": list.reach,
      "date_start": list.date_start,
      "date_stop": list.date_stop
    }
  })

  const dateFormatter = (dates) => {
    return moment(dates).format("MMM D");
  };

  useEffect(() => {
    props.fetchInsightsGraph(startdate, enddate,id)
  }, [startdate, enddate,id])

  return (
    <ResponsiveContainer width="100%" height={370}>

      <LineChart width={730} height={250} data={conversions}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date_start" tickFormatter={dateFormatter} />
        <YAxis type="number" domain={[0, 'dataMax']} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="impressions" stroke="orange" fill="orange" />
        <Line type="monotone" dataKey="lead" stroke="#82ca9d" fill="#82ca9d" />
        <Line type="monotone" dataKey="reach" stroke="#8884d8" fill="#8884d8" />
      </LineChart>

    </ResponsiveContainer>
  );
}
const mapStateToProps = (state) => ({
  error: state.applications.error,
  success: state.applications.success,
  loading: state.applications.loading,
  insightsGraph: state.applications.insightsGraph,

  auth: state.auth

})

const mapActionsToProps = {
  fetchInsightsGraph,

};
export default connect(mapStateToProps, mapActionsToProps)(ImpressionsChart);