import * as requestFromServer from "./retailerCrud";
import { retailerSlice } from "./retailerSlice";
import { getErrorMessage } from "../../../utils";

const { actions } = retailerSlice;

/* ========= Create Retailer ========= */

export const PostRetailer = (data) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    requestFromServer
      .CreateRetailer(data)
      .then((response) => {
        dispatch(
          actions.catchSuccessMessage({
            success: "Retailer Created successfully",
          })
        );
        const retailerResponse = response.data;
        dispatch(actions.retailersData({ retailerResponse }));
        resolve(response);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};

/* ========= Get Retailers ========= */

export const fetchRetailers = (token) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .getALLRetailers(token)
    .then((response) => {
      const retailerResponse = response.data;
      console.log("bookingResponsne4", retailerResponse);
      dispatch(actions.retailersData({ retailerResponse }));
    })
    .catch((error) => {});
};
export const EditRetailers = (data) => (dispatch) => {
  dispatch(actions.EditRetailer(data));
  console.log("dataaa", data);
};

//UpdateRetailerApi

export const fetchUpdateRetailers = (token, formData, appId) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    return requestFromServer
      .UpdateRetailer(token, formData, appId)
      .then((response) => {
        const UpdateResponse = response.data;
        console.log("bookingResponsne4", UpdateResponse);
        dispatch(actions.neweditdata({ UpdateResponse }));
        resolve(response);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};

//RemoveImageRetailer
export const RemoveImage = (token, imageName,id) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    return requestFromServer
      .DeleteImage(token, imageName,id)
      .then((response) => {
        resolve(response);
      })

      .catch((error) => {
        console.log("error", error.message);
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};
export const RetailerDelete = (token, appId) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    return requestFromServer
      .DeleteList(token, appId)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};