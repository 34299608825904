import React from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays } from 'date-fns';
import { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
const DateRange=()=>{
  
const [state, setState] = useState({
  selection1: {
    startDate: addDays(new Date(), 1),
    endDate: null,
    key: 'selection1'
  },
 

});
return(
    <div>
<DateRangePicker
  onChange={item => setState({ ...state, ...item })}
  ranges={[state.selection1]}
  moveRangeOnFirstSelection={false}
  showPreview={false}
/>;
</div>)
}
export default DateRange;