import { createSlice } from "@reduxjs/toolkit";

const initialRetailerState = {
  loading: false,
  success: "",
  error: "",
  getRetailers: [],
  EditRetailersData: {},
  retailerEditData: [],
};

export const retailerSlice = createSlice({
  name: "retailer",
  initialState: initialRetailerState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      state.loading = false;
    },
    catchSuccessMessage: (state, action) => {
      state.success = `${action.payload.success}`;
      state.loading = false;
    },
    startCall: (state) => {
      state.error = null;
      state.loading = true;
    },
    EditRetailer: (state, action) => {
      state.EditRetailersData = action.payload;
    },

    neweditdata: (state, action) => {
      state.loading = false;
      state.success = action.payload.success;
      state.retailerEditData = action.payload.UpdateResponse;
      state.error = null;
    },

    // coupon
    retailersData: (state, action) => {
      console.log("action payload", action.payload);
      state.loading = false;
      state.success = action.payload.success;
      state.getRetailers = action.payload.retailerResponse;
      state.error = null;
    },
  },
});