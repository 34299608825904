import React, { useMemo, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import CreateAppForm from "./CreateAppForm";

export function CreateAppPage() {
  return (
    <Card>
      <CardHeader title="Create App">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CreateAppForm />
      </CardBody>
    </Card>
  );
}
