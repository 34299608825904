import axios from "axios";
import __env from "../../../../env";

// CREATE =>  POST: add a new retailer to the server

export function CreateRetailer(formData) {
  return axios.post(
    `${__env.dataApi}/auth/create-retailer?appId=${""}`,
    formData
  );
}

// READ  =>  GET: retailer list

export function getALLRetailers(token) {
  return axios.get(`${__env.dataApi}/adminDashboard/getRetailers`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
}

export function UpdateRetailer(token, formData, appId) {
  return axios.put(
    `${__env.dataApi}/retailer/updateRetailer/${appId}`,
    formData,
    {
      headers: { Authorization: `Bearer ${token || ""}` },
    }
  );
}

export function DeleteImage(token, imageNmae,id) {
  return axios.delete(
    `${__env.dataApi}/retailer/deleteImages?fileName=${imageNmae}&id=${id}`,
    { headers: { Authorization: `Bearer ${token || ""}` } }
  );
}

export function DeleteList(token, appId) {
  return axios.delete(`${__env.dataApi}/users/deleteRetailer/${appId}`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
}