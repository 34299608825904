import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getHandlerTableChange,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { TextField, Grid } from "@material-ui/core";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* ========= React-redux ========= */
import { connect } from "react-redux";
import { fetchAppAssignedCoupon } from "../../pages/Applications/_redux/organizationsActions";
import { Redirect } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
/* ========= style for form ========= */
import "../../../styles/applicationTable.scss";

function AssignedCoupons({ ...props }) {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    props.fetchAppAssignedCoupon(id);
  }, []);
  console.log("props", props.appAssignedCoupon)

 const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
 sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },

    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },
    {
      dataField: "phoneNo",
      text: "Phone Number",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
      },

  ];

  
  const paginationOptions = {
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
           
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-align-top-left cursor"
              bootstrap4
              // remote
              keyField="id"
              hover={true}
              data={props.appAssignedCoupon == null ? [] : props.appAssignedCoupon}
              columns={columns}
              noDataIndication={<div className='text-center'>No Data Found</div>}
              //defaultSorted={uiHelpers.defaultSorted}
              onTableChange={getHandlerTableChange()
                //applicationsUIProps.setQueryParams
              }
              filter={filterFactory()}
              {...paginationTableProps}
            >
              {/*<PleaseWaitMessage entities={entities} />*/}
              {/*<NoRecordsFoundMessage entities={entities} />*/}
            </BootstrapTable>
          );
        }}
      </PaginationProvider>
    </>
  );
}
const mapStateToProps = (state) => ({
  error: state.applications.error,
  success: state.applications.success,
  loading: state.applications.loading,
  appAssignedCoupon: state.applications.appAssignedCoupon,

});

const mapActionsToProps = {
  fetchAppAssignedCoupon,
};
export default connect(mapStateToProps, mapActionsToProps)(AssignedCoupons);
