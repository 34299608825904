/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector, shallowEqual } from "react-redux";
import Can from "../../../../../app/components/Can";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };
  const { roles } = useSelector(
    ({ auth }) => ({
      roles: auth.user.roles
    }),
    shallowEqual
  );

  console.log(roles);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>


        {/*begin::1 Level*/}
        <Can
          roles={roles}
          perform="experts-list:visit"
          yes={() => (
            <li
              className={`menu-item ${getMenuItemActive("/experts")}`}
              aria-haspopup="true"
            >
              <NavLink exact className="menu-link" to="/experts" activeClassName="selected">
                <span className="fas fa-stethoscope menu-icon"></span>
                <span className="menu-text">Experts</span>
              </NavLink>
            </li>
          )}
          no={() => null}
        />
        {/*end::1 Level*/}


        {/*begin::1 Level*/}

    
        <Can
          roles={roles}
          perform="retailers-page:visit"
          yes={() => (
            <li
              className={`menu-item ${getMenuItemActive("/retailers")}`}
              aria-haspopup="true"
            >
              <NavLink exact className="menu-link" to="/retailers">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                </span>
                <span className="menu-text">Retailers</span>
              </NavLink>
            </li>
          )}
          no={() => null}
        />

        <Can
        roles={roles}
        perform="retailers-page:visit"
        yes={() => (
          <li
            className={`menu-item ${getMenuItemActive("/apps")}`}
            aria-haspopup="true"
          >
            <NavLink exact className="menu-link" to="/apps">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">Apps</span>
            </NavLink>
          </li>
        )}
        no={() => null}
      />
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/*
        <Can
          roles={roles}
          perform="users-list:visit"
          yes={() => (
            <li
              className={`menu-item ${getMenuItemActive("/users")}`}
              aria-haspopup="true"
            >
              <NavLink exact className="menu-link" to="/users">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
                </span>
                <span className="menu-text">Users</span>
              </NavLink>
            </li>
          )}
          no={() => null}
        />
         */}
        {/*end::1 Level*/}
         
        {/*begin::1 Level*/}
      {/*  <Can
          roles={roles}
          perform="organizations-list:visit"
          yes={() => (
            <li
              className={`menu-item ${getMenuItemActive("/organizations")}`}
              aria-haspopup="true"
            >
              <NavLink exact className="menu-link" to="/organizations">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")} />
                </span>
                <span className="menu-text">Organizations</span>
              </NavLink>
            </li>
          )}
          no={() => null}
          />*/}

          <Can
          roles={roles}
          perform="retailers-page:visit"
          yes={() => (
            <li
              className={`menu-item ${getMenuItemActive("/allcoupons")}`}
              aria-haspopup="true"
            >
              <NavLink exact className="menu-link" to="/allcoupons">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")} />
                </span>
                <span className="menu-text">Coupons</span>
              </NavLink>
            </li>
          )}
          no={() => null}
        />

        

       {/* <Can
        roles={roles}
        perform="retailers-page:visit"
        yes={() => (
          <li
            className={`menu-item ${getMenuItemActive("/get-bookings")}`}
            aria-haspopup="true"
          >
            <NavLink exact className="menu-link" to="/get-bookings">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
              </span>
              <span className="menu-text">Bookings</span>
            </NavLink>
          </li>
        )}
        no={() => null}
      />
       */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
       
        <Can
          roles={roles}
          perform="manage-lists:visit"
          yes={() => (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/"
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink exact className="menu-link menu-toggle" to="/" >
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
                </span>
                <span className="menu-text">Manage</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className={`menu-item  ${getMenuItemActive(
                      "/experts/list"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink exact className="menu-link"
                      to="/experts/list" >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Experts List</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item  ${getMenuItemActive(
                      "/holidays"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink exact className="menu-link"
                      to="/holidays" >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Holidays</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item  ${getMenuItemActive(
                      "/experts/customers"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink exact className="menu-link"
                      to="/experts/customers" >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Customers</span>
                    </NavLink>
                  </li>
                   {/*end::2 Level*/}

                </ul>
              </div>
            </li>
          )}
          no={() => null}
        />
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <Can
          roles={roles}
          perform="holidays-list:visit"
          yes={() => (
            <li
              className={`menu-item ${getMenuItemActive("/holidays")}`}
              aria-haspopup="true"
            >
              <NavLink exact className="menu-link" to="/holidays">
                <span className="far fa-calendar menu-icon"></span>
                <span className="menu-text">Holidays</span>
              </NavLink>
            </li>
          )}
          no={() => null}
        />
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <Can
          roles={roles}
          perform="dashboard-page:visit"
          yes={() => (
            <li
              className={`menu-item ${getMenuItemActive("/applications")}`}
              aria-haspopup="true"
            >
              <NavLink exact className="menu-link" to="/applications">
                <span className="far fa-calendar menu-icon"></span>
                <span className="menu-text">Applications</span>
              </NavLink>
            </li>
          )}
          no={() => null}
        />
        {/*end::1 Level*/}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
