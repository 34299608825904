import axios from "axios";
import __env from "../../../../env";

// CREATE =>  POST: add a new app to the server

export function createApp(token, data) {
  return axios.post(`${__env.dataApi}/apps/createApp`, data, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
}

// READ  =>  GET: retailer apps list
export function getAllApps(id) {
  return axios.get(`${__env.dataApi}/adminDashboard/getRetailers/${id}`);
}

// ASSIGN =>  POST: assign a new app to the retailer

export function AppsAssigning(token, appId, id) {
  return axios.post(
    `${__env.dataApi}/adminDashboard/assigning/${id}?appId=${appId}`,
    { headers: { Authorization: `Bearer ${token || ""}` } }
  );
}

// READ  =>  GET: all apps list
export function fetchAppsList(token) {
  return axios.get(`${__env.dataApi}/adminDashboard/allApps/`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
}

// READ  =>  GET: apps category list
export function GetAllCategories(token) {
  return axios.get(`${__env.dataApi}/get_categories`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
}

// READ  =>  GET: apps landingpage list
export function GetAllPages(token, category) {
  return axios.get(`${__env.dataApi}/getPages/${category}`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
}

// UPDATE => PUT: update the app on the server
export function EditApp(token, formData, appId) {
  return axios.put(`${__env.dataApi}/apps/updateApp/${appId}`, formData, {
    headers: {
      Authorization: `Bearer ${token || ""}`,
      "Content-Type": "multipart/form-data",
    },
  });
}
export function DeleteImage(token,file,appId) {
  return axios.delete(`${__env.dataApi}/apps/delete?fileName=${file}&appId=${appId}`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
}

export function DeleteApp(token, appId) {
  return axios.delete(`${__env.dataApi}/apps/delete/${appId}`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
}
