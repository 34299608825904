import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

//import * as uiHelpers from "../ApplicationsUIHelpers";
//import * as columnFormatters from "./column-formatters";

import { Pagination } from "../../../../_metronic/_partials/controls";

/* ========= react-redux ========= */
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchRetailers, EditRetailers } from "../_redux/retailerAction";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { RetailerDelete } from "../../../pages/Applications/_redux/retailerAction";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Type } from "react-bootstrap-table2-editor";
//import { useApplicationsUIContext } from "../ApplicationsUIContext";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import SVG from "react-inlinesvg";
import { TextField, Grid, Modal, Paper } from "@material-ui/core";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";

/* ========= style for form ========= */
import "../../../../styles/applicationTable.scss";
import "react-toastify/dist/ReactToastify.css";
import { getErrorMessage } from "../../../utils";

function ApplicationsTable({ auth, ...props }) {
  const history = useHistory();
  const [react, setReact] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (auth.user) {
      props.fetchRetailers(auth.authToken);
    }
  }, [auth, fetchRetailers]);

  const tableRowEvents = {
    onClick: (e, row) => {
      history.push(`/applist/${row.retailer.id}`);
    },
  };
  
  const [deleteListId, setDeleteListId] = useState(null);

  const handleDelete = (aapId) => {
    if (auth) {
      props
        .RetailerDelete(auth.authToken, deleteListId.id)

        .then((response) => {
          toast.success("Retailer deleted Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });

          setReact(false);
          setTimeout(() => window.location.reload(true), 1000)
        })
        .catch((err) => {
          toast.error("Something went wrong", {
            postion: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        });
    }
  };

  const customHeaderTemplate = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ height: "20px" }}>
          <span>
            {column.text} {sortElement}
          </span>
        </div>
        <div>{filterElement}</div>
      </div>
    );
  };

  // Table column
  const handleClick = (id) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        toast.success("Copied!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch(() => {
        toast.error("Something went wrong!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          pauseOnHover: true,
          progress: undefined,
        });
      });
  };
  const selectOptionsArr = [
    {
      value: false,
      label: "Active",
    },
    {
      value: true,
      label: "InActive",
    },
  ];

  const columns = [
    // {
    //   dataField: "retailer.id",
    //   text: "Retailer ID",
    //   sort: true,
    //   accessor: "link",
    //   sortCaret: sortCaret,
    //   headerSortingClasses,

    // },

    {
      dataField: "retailer.logo",
      dataFormat: "imageFormatter",
      text: "Logo",
      sort: true,
      accessor: "link",
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },

    {
      dataField: "name",
      text: "Name",
      sort: true,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },
    {
      dataField: "mobile",
      text: "Phone Number",
      formatter: (cell, row) => {
        return (
          <Grid container={true} style={{ width: "100%" }}>
            <div style={{ display: "block" }}></div>
            <div style={{ display: "flex", width: "100px" }}>
              <TextField
                disabled
                id="filled-disabled"
                defaultValue={row.mobile}
                variant="filled"
                type="password"
                style={{ borderTopRightRadius: "0px", width: "60%" }}
              />
              <div className="symbol symbol-50 symbol-light mr-1">
                <span
                  className="symbol-label"
                  style={{
                    backgroundColor: "#f5f7fa",
                    borderRadius: "0",
                    height: "39.44px",
                    borderTopRightRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    handleClick(row.mobile)
                    e.stopPropagation()
                  }}

                >

                  <img
                    src={toAbsoluteUrl("/media/svg/icons/Media/copy.png")}
                    className="h-50 align-self-center"
                  />
                  <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    transition={Slide}
                    rtl={false}
                    pauseOnFocusLoss
                    pauseOnHover
                  />
                </span>
              </div>
            </div>
          </Grid>
        );
      },
      sort: true,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },

    {
      dataField: "retailer.retailerImage",
      text: "RetailerImage",
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },

    {
      text: "Action",
      filter: selectFilter({
        options: selectOptionsArr,
        defaultValue: "",
      }),
      formatter: (cellContent, row) => {
        return (
          <Grid container={true} style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                padding: "6px",
              }}
            >
              <a
                title="Edit user"
                className="btn btn-icon btn-light btn-hover-secondary btn-sm"
                onClick={(e) => {
                  props.EditRetailers(row);
                  e.stopPropagation();
                  history.push("/editretailer");
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>
              <> </>

              <a
                title="Delete user"
                className="btn btn-icon btn-light btn-hover-secondary btn-sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setReact(true);
                  return setDeleteListId(row);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-dark">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                  />
                </span>
              </a>
            </div>
          </Grid>
        );
      },
    },

    // {
    //   dataField: "appid",
    //   text: "AppID",
    //   formatter: (cell,row) => {
    //     return (
    //       <Grid container={true} style={{width:"120%"}}>
    //         <TextField
    //               disabled
    //               id="filled-disabled"
    //               defaultValue={row.appid}
    //               variant="filled"
    //               type="password"
    //               style={{borderTopRightRadius: "0px"}}
    //         />
    //         <div className="symbol symbol-50 symbol-light mr-1">
    //             <span
    //             className="symbol-label"
    //             style={{
    //                 backgroundColor:"#f5f7fa",
    //                 borderRadius:"0",
    //                 height: "39.44px",
    //                 borderTopRightRadius: "4px",
    //                 cursor: "pointer",
    //             }}
    //             onClick={() => handleClick(row.appid)}
    //             >
    //                 <img
    //                     src={toAbsoluteUrl("/media/svg/icons/Media/copy.png")}
    //                     className="h-50 align-self-center"
    //                 />
    //                 <ToastContainer position="top-center"
    //                   autoClose={1000}
    //                   hideProgressBar={true}
    //                   newestOnTop={false}
    //                   transition={Slide}
    //                   rtl={false}
    //                   pauseOnFocusLoss
    //                   pauseOnHover
    //                 />
    //             </span>
    //         </div>
    //       </Grid>
    //     );
    //   },
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "120%",
    //   },
    // },
  ];

  const PaginationOptions = {
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  };

  return (
    <>
      {/*<Button onClick={() => setReact(true)}>open Dialog</Button>*/}
      <Dialog open={react} onClose={() => setReact(false)}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to delete this Retailer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReact(false)}>No</Button>
          <Button onClick={() => handleDelete(props.allApps)}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Modal
        className="d-flex justify-content-center align-items-center w-100 h-500"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Paper>
          <div>
            <div className="text-primary font-weight-bold font-size-h2 ml-10 mt-4 d-inline text-center w-100">
              Assigned CouponsList
            </div>
          </div>
        </Paper>
      </Modal>

      <PaginationProvider pagination={paginationFactory(PaginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-align-top-left cursor"
              bootstrap4
              keyField="id"
              hover={true}
              data={
                props?.retailers === null
                  ? []
                  : props?.retailers?.map((d) => {
                    return {
                      ...d,
                      retailer: {
                        ...d.retailer,
                        retailerImage: (
                          <img
                            src={d.retailer?.retailerImage}
                            style={{ height: "40px", width: "70px" }}
                          />
                        ),
                        logo: (
                          <img
                            src={d.retailer?.logo}
                            style={{ height: "50px", width: "70px" }}
                          />
                        ),
                      },
                    };
                  })
              }
              columns={columns}
              noDataIndication={
                <div
                  className="text-center"
                  style={{ color: "#212121", marginTop: "10px" }}
                >
                  No retailer found
                </div>
              }
              rowEvents={tableRowEvents}
              onTableChange={getHandlerTableChange()}
              filter={filterFactory()}
              {...paginationTableProps}
            ></BootstrapTable>
          );
        }}
      </PaginationProvider>
    </>
  );
}
const mapStateToProps = (state) => ({
  error: state.retailer.error,
  success: state.retailer.success,
  loading: state.retailer.loading,
  retailers: state.retailer.getRetailers,
  auth: state.auth,
});

const mapActionsToProps = {
  fetchRetailers,
  EditRetailers,
  RetailerDelete,
};
export default connect(mapStateToProps, mapActionsToProps)(ApplicationsTable);