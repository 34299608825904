import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {Button} from "@material-ui/core"
import {toast} from 'react-toastify';
import {connect} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { fetchInsightsGraph } from "../../pages/Applications/_redux/organizationsActions";
// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DatePickers = ({...props}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const startdate= moment(startDate).format("YYYY-MM-DD")
 const enddate= moment(endDate).format("YYYY-MM-DD")

  const handleSubmit = () =>{
 const formData=new FormData()
 formData.append("startDate",startdate)
 formData.append("endDate",enddate)
   props.fetchInsightsGraph(formData).then(response =>{
     toast.success("Fetching data successfully",{position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000})
   }).catch(err=>{
     toast.error("Fetching data failed",{position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000})
   })
 }
 
 
  return (
    <>
    <div> <DatePicker
     selected={startDate}
      onChange={(date) => setStartDate(date)}
       className="p-2 mb-2 mt-2 mr-4" /></div>
    <div> 
    <DatePicker
     selected={endDate}
      onChange={(date) => setEndDate(date)} 
      className="p-2 mb-2 mt-2"/>
    </div>
    <div>
    <Button onClick={handleSubmit}>Apply</Button></div>
  
    </>
  );
};
const mapStateToProps = (state) => ({
  success:state.applications.success,
  insightsGraph:state.applications.insightsGraph,
})
const mapActionsToProps={
  fetchInsightsGraph
}
export default connect(mapStateToProps, mapActionsToProps)(DatePickers);