import React, { useMemo, useEffect, useState, useRef } from "react";
import DatePicker from 'react-datepicker';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Input } from "../../../../_metronic/_partials/controls";

/* ========= Related to the form ========= */
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { toast } from "react-toastify";
import {
  FilePlus as AddFileIcon
} from "react-feather";
/* ========= Material UI icons ========= */
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Select,
  CircularProgress,
  MenuItem,
  IconButton
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import AttachmentIcon from '@material-ui/icons/Attachment';
import moment from "moment";

/* ========= react-redux ========= */
import { useDispatch } from "react-redux";
import { connect } from 'react-redux';
import { PostRetailer } from "../_redux/retailerAction";
import { useHistory } from "react-router-dom";

/* ========= style for form ========= */
import "../../../../styles/createUserForm.scss";
import "react-datepicker/dist/react-datepicker.css";

function CreateUserForm({ ...props }) {
  const history = useHistory();
  const [logoSelected, setLogoSelected] = useState(null);
  const [isLogoPicked, setLogoPicked] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);
  const [isImagePicked, setImagePicked] = useState(false);
  const [loading, setLoading] = useState(false)
  const logoRef = useRef();
  const imageRef = useRef();

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const handleMedia = (event) => {
    if (event) {
      setLogoSelected(event.target.files[0]);
      setLogoPicked(true);
    }
  }
  const onRemove = () => {
    setLogoPicked(false)
    setLogoSelected(null)
  }

  const handleImageMedia = (event) => {
    if (event) {
      setImageSelected(event.target.files[0]);
      setImagePicked(true);
    }
  }

  const onImageRemove = () => {
    setImagePicked(false)
    setImageSelected(null)
  }

  const handleRetailer = (values) => {
    const formData = new FormData()
    formData.append("logo", logoSelected)
    formData.append("retailerImage", imageSelected)
    formData.append("name", values["name"])
    formData.append("email", values["email"])
    formData.append("mobile", values["mobile"])

    if (formData) {
      setLoading(true)
      props.PostRetailer(formData)
      
      .then(response => {
        toast.success('Retailer created successfully', { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 })
        setLoading(false)
        values = {
          email: "",
          mobile: "",
          name: "",
        };

      }).catch(err => {
        const errorDetails = err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong";
        toast.error(errorDetails, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 })
        setLoading(false);
      })
    }
  }

  return (
    <Box p={2}>
      <Formik
        initialValues={{}}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          // name: Yup.string().required("Employee code is required."),
          // email: Yup.string()
          //   .email("Invalid email address format")
          //   .required("Email is required"),
          // mobile: Yup.string().required("phoneNumber is required")

        })}
        onSubmit={handleRetailer}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          isSubmitting,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <div className="col-lg-12">
                  <div className="form-group">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      value={values.name}
                      onChange={handleChange}
                      withFeedbackLabel={false}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <Field
                      name="email"
                      component={Input}
                      placeholder="Email"
                      label="Email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      withFeedbackLabel={false}
                      required
                    />
                  </div>
                </div>
                <Box mt={2}>
                  <Typography>Retailer Logo<span style={{ color: 'red' }}>*</span></Typography>
                  <Box className="od__uploadFile_border">
                    <input
                      type="file"
                      ref={logoRef}
                      name="logo"
                      onChange={handleMedia}
                      style={{ display: 'none' }}
                    />
                    {
                      !isLogoPicked
                        ?
                        <Box
                          className="od__uploadFile"
                          onClick={() => logoRef.current.click()}
                        >
                          <AddFileIcon />
                          <Typography>Upload Logo</Typography>
                        </Box>
                        :
                        <div>
                          <Box
                            className="od__uploadFile"
                          >
                            <AttachmentIcon />
                            <Box>
                              <Typography>{logoSelected.name}</Typography>
                            </Box>
                          </Box>
                          <IconButton
                            className="od__uploadFile__remove"
                            onClick={onRemove}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                    }
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {/*  <Box>
                      <Typography>Open Time <span style={{color: 'red'}}>*</span></Typography>
                      <DatePicker
                        selected={startDate}
                        className="od__textField datepicker"
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </Box>
                    <Box mt={2}>
                      <Typography>Close Time <span style={{color: 'red'}}>*</span></Typography>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        className="od__textField datepicker"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </Box>*/}
                <div className="col-lg-12">
                  <div className="form-group">
                    <Field
                      name="mobile"
                      component={Input}
                      placeholder="Mobile"
                      label="Mobile"
                      value={values.mobile}
                      onChange={handleChange}
                      withFeedbackLabel={false}
                      maxLength="10"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12" style={{ visibility: 'hidden' }}>
                  <div className="form-group">
                    <Field
                      name="appId"
                      component={Input}
                      placeholder="AppID"
                      label="AppID"
                      value={values.appId}
                      onChange={handleChange}
                      withFeedbackLabel={false}
                    />
                  </div>
                </div>

                <Box mt={3}>
                  <Typography>Retailer Image<span style={{ color: 'red' }}>*</span></Typography>
                  <Box className="od__uploadFile_border">
                    <input
                      type="file"
                      ref={imageRef}
                      name="retailerImage"
                      onChange={handleImageMedia}
                      style={{ display: 'none' }}
                      required
                    />
                    {
                      !isImagePicked
                        ?
                        <Box
                          className="od__uploadFile"
                          onClick={() => imageRef.current.click()}
                        >
                          <AddFileIcon />
                          <Typography>Upload Image</Typography>
                        </Box>
                        :
                        <div>
                          <Box
                            className="od__uploadFile"
                          >
                            <AttachmentIcon />
                            <Box>
                              <Typography>{imageSelected.name}</Typography>
                            </Box>
                          </Box>
                          <IconButton
                            className="od__uploadFile__remove"
                            onClick={onImageRemove}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                    }
                  </Box>
                </Box>
                <Box mt={3}>
                  <Button
                    color="primary"
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    endIcon={loading ? <CircularProgress color="inherit" size={20} /> : ''}
                  >
                    Add Retailer
                  </Button>
                </Box>
              </Grid>

            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  error: state.retailer.error,
  success: state.retailer.success,
  loading: state.retailer.loading,
  retailers: state.retailer.retailers,
})

const mapActionsToProps = {
  PostRetailer
};
export default connect(mapStateToProps, mapActionsToProps)(CreateUserForm);
