
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import {Typography} from "@material-ui/core"

import "react-datepicker/dist/react-datepicker.css";
import {
    MixedWidget1,
    MixedWidget14,
    ListsWidget9,
    StatsWidget11,
    StatsWidget12,
    ListsWidget1,
    AdvanceTablesWidget2,
    AdvanceTablesWidget4,
    ListsWidget3,
    ListsWidget4,
    ListsWidget8
} from "../widgets";
import DateRange from "../../../app/components/RetailerApplications/DateRange";
const  Demo1Dashboard=() =>{
    const [startDate, setStartDate] = useState(new Date());
    return (<>
        <div className="app-container container-xxl d-flex flex-stack">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <h3 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0"> Analytics Dashboard</h3></div>
      
      <div>
       <DateRange/>
        </div>
     
        </div>
            <div className="row">
                <div className="col-lg-6 col-xxl-4">
                    <MixedWidget1 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-6 col-xxl-4">
                    <ListsWidget9 className="card-stretch gutter-b"/>
                </div>

                <div className="col-lg-6 col-xxl-4">
                    <StatsWidget11 className="card-stretch card-stretch-half gutter-b"/>
                    <StatsWidget12 className="card-stretch card-stretch-half gutter-b"/>
                </div>

                <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
                    <ListsWidget1 className="card-stretch gutter-b"/>
                </div>
                <div className="col-xxl-8 order-2 order-xxl-1">
                    <AdvanceTablesWidget2 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget3 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget4 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget8 className="card-stretch gutter-b"/>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <MixedWidget14 className="card-stretch gutter-b" />
                </div>
                <div className="col-lg-8">
                    <AdvanceTablesWidget4 className="card-stretch gutter-b" />
                </div>
            </div>
    </>);
}
export default Demo1Dashboard;
