import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

import AppListTable  from "./app-list-table/AppListTable";
// import Qr from '../../components/AdminDashboard/Qr'
import { useDispatch } from "react-redux";
//import * as actions from "./_redux/organizationsActions";


export function AppCard() {
   
//   const organizationsUIContext = useOrganizationsUIContext();
//   const organizationsUIProps = useMemo(() => {
//     return {
//     ids: organizationsUIContext.ids,
//       newOrganizationButtonClick: organizationsUIContext.newOrganizationButtonClick,
//     };
//   }, [organizationsUIContext]);

  // const { currentState } = useSelector(
  //   (state) => ({ currentState: state.organizations }),
  //   shallowEqual
  // );
  // const { totalCount, entities, listLoading } = currentState;
  
  const dispatch = useDispatch();

  //useEffect(() => {
    //dispatch(actions.fetchOrganizations());
  //}, [dispatch])
  
  return (
    <Card>
      <CardHeader title=" App list">
        <CardHeaderToolbar>
      
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
 
        <AppListTable />
      </CardBody>
    </Card>
  );
}
