import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";

import AllAppsList from "./AllAppsList";

import { useDispatch } from "react-redux";
//import * as actions from "./_redux/organizationsActions";

export function AppsCard() {
  return (
    <Card>
      <CardHeader title="Apps list">
        <CardHeaderToolbar>
          <Link to="/create-app">
            <button
              type="button"
              className="btn btn-primary ml-2"
              data-toggle="modal"
              data-target="#exampleModalLong"
            >
              <i className="ki ki-menu icon-md mr-2"></i>
              Create App
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <AllAppsList />
      </CardBody>
    </Card>
  );
}
