import React, { useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import ApplicationsTable  from "./applications-table/ApplicationsTable";
import { useDispatch } from "react-redux";

export function ApplicationsCard() {

const dispatch = useDispatch();
  
  return (
    <Card>
      <CardHeader title="Retailers list">
        <CardHeaderToolbar>
        <Link to="/create-user">
          <button
            type="button"
            className="btn btn-primary"
          >
            New Retailer
          </button></Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ApplicationsTable />
        
      </CardBody>
    </Card>
  );
}
