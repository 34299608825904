import React, { useEffect } from "react";
import {
  LineChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import {format} from 'date-fns';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { fetchAvailedCouponGraph } from '../../pages/Applications/_redux/organizationsActions';

const AvailedCouponChart = ({ auth, ...props }) => {
  const { id } = useParams();
 const dateFormatter =(date) => {
    return moment(date).format("MMM D");
  };
  
  useEffect(() => {
    if (auth) {
      props.fetchAvailedCouponGraph(id,auth.authToken)
      }
   }, [])

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        width={500}
        height={400}
        data={props.availedCouponGraph}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" 
        tickFormatter={dateFormatter}
       />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="dataCount" stroke="#1d738b" fill="#1d738b" />
      </LineChart>
    </ResponsiveContainer>
  );
}
const mapStateToProps = (state) => ({
  error: state.applications.error,
  success: state.applications.success,
  loading: state.applications.loading,
  availedCouponGraph: state.applications.availedCouponGraph,
  auth: state.auth

})

const mapActionsToProps = {
  fetchAvailedCouponGraph

};
export default connect(mapStateToProps, mapActionsToProps)(AvailedCouponChart);
