import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../../../pages/AppList/AppUIHelpers";

import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

import SVG from "react-inlinesvg";
import { TextField, Grid, Button, Modal, Paper, Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import { ToastContainer, toast, Slide } from "react-toastify";

/* ========= react-router-dom ========= */
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

/* ========= react-redux ========= */
import { shallowEqual, connect, useSelector } from "react-redux";
import {
  FetchAllApps,
  AssigningApps,
} from "../../../pages/AppList/redux/appAction";
import { fetchRetailers } from "../../../pages/Applications/_redux/retailerAction";

import { AppDelete } from "../../../pages/AppList/redux/appAction";

/* ========= style for form ========= */
import "../../../../styles/applicationTable.scss";

import { getApplicationDetailData } from "../../../pages/Applications/_redux/organizationsCrud";
import { EditAppData } from "../../../pages/AppList/redux/appAction";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import "react-toastify/dist/ReactToastify.css";

function AllApps({ auth, appEditData, ...props }) {
  const history = useHistory();

  const [react, setReact] = useState(false);
  console.log("react", react);

  const [disable, setDisable] = useState(true);
  const [appId, setAppId] = useState([]);
  const [value, setValue] = useState("Select retailer to assign app");
  const [open, setOpen] = useState(false);
  const [deleteAppID, setDeleteAppId] = useState(null);
  const editData = useSelector((state) => state.apps.appEditData);

  useEffect(() => {
    if (auth) {
      props.FetchAllApps(auth.authToken);
    }
  }, [auth, FetchAllApps]);

  useEffect(() => {
    if (auth) {
      props.fetchRetailers(auth.authToken);
    }
    console.log("props", props.allApps);
  }, [auth, fetchRetailers]);

  const handleDelete = (id) => {
    if (auth) {
      props
        .AppDelete(auth.authToken, deleteAppID.id)
        .then((response) => {
          toast.success("Apps deleted successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });

          setReact(false);
         setTimeout(()=> window.location.reload(true),1000)
        })
        .catch((err) => {
          toast.error("Something went wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        });
    }
  };

  const handleClick = (id) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        toast.success("Copied!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch(() => {
        toast.error("Something went wrong!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          pauseOnHover: true,
          progress: undefined,
        });
      });
  };

  const selectRow = {
    mode: "checkBox",
    clickToSelect: true,
    color: "#def3ff",
    onSelect: (row, isSelected, e) => {
      if (isSelected) {
        const ids = new Set([...appId, row.appId]);
        setAppId(Array.from(ids));
        console.log("appid", ids);
        setDisable(false);
        console.log(isSelected);
        console.log("value", e);
      } else {
        setAppId(appId.filter((id) => id !== row.appId));
      }
    },
  };
  const handleSelect = (id) => {
    console.log(id);
    setValue(id);
  };

  const handleApps = () => {
    if (auth) {
      props
        .AssigningApps(auth.authToken, appId, value)
        .then((response) => {
          toast.success("Apps assigned  successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
        })

        .catch((err) => {
          const errorDetails = err?.response?.data
            ? err.response.data
            : "Something went wrong";
          toast.error(errorDetails, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
        });
    }
  };

  const customHeaderTemplate = (
    column,
    colIndex,
    { sortElement, filterElement }
  ) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ height: "20px" }}>
          <span>
            {column.text} {sortElement}
          </span>
        </div>
        <div>{filterElement}</div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "appName",
      text: "AppName",
      sort: true,
      accessor: "link",
      // sortCaret: sortCaret,
      // headerSortingClasses,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },

    {
      dataField: "tagLine",
      text: "TagLine",
      sort: true,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },

    {
      dataField: "address",
      text: "Address",
      sort: true,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },

    {
      dataField: "categories",
      text: "Category",
      sort: true,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },
    {
      dataField: "assigned",
      text: "Assigned",
      sort: true,
      filter: textFilter(),
      headerFormatter: customHeaderTemplate,
    },

    {
      dataField: "appId",
      text: "AppID",
      formatter: (cell, row) => {
        return (
          <Grid container={true} style={{ width: "100%" }}>
            <div style={{ display: "block" }}>
              <Link
                to={`/create-coupon/${row.appId}`}
                style={{ color: "blue" }}
              >
                Create Coupon
              </Link>
            </div>
            <div style={{ display: "flex", width: "100px" }}>
              <TextField
                disabled
                id="filled-disabled"
                defaultValue={row.appId}
                variant="filled"
                type="password"
                style={{ borderTopRightRadius: "0px", width: "60%" }}
              />
              <div className="symbol symbol-50 symbol-light mr-1">
                <span
                  className="symbol-label"
                  style={{
                    backgroundColor: "#f5f7fa",
                    borderRadius: "0",
                    height: "39.44px",
                    borderTopRightRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {handleClick(row.appId)
                  e.stopPropagation()}}
                >
                  <img
                    src={toAbsoluteUrl("/media/svg/icons/Media/copy.png")}
                    className="h-50 align-self-center"
                  />
                  <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    transition={Slide}
                    rtl={false}
                    pauseOnFocusLoss
                    pauseOnHover
                  />
                </span>
              </div>
            </div>
          </Grid>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120%",
      },
    },
    {
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "1rem",
              padding: "6px",
            }}
          >
            <a
              title="Edit user"
              className="btn btn-icon btn-light btn-hover-secondary btn-sm"
              onClick={() => {
                props.EditAppData(row);
                history.push("/appeditpage");
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>
            <> </>

            <a
              title="Delete user"
              className="btn btn-icon btn-light btn-hover-secondary btn-sm"
              onClick={() => {
                setReact(true);
                return setDeleteAppId(row);
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-dark">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
            </a>
          </div>
        );
      },
    },
  ];
  // Table pagination properties
  const PaginationOptions = {
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function(page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function(page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  };
  return (
    <>
      {/*<Button onClick={() => setReact(true)}>open Dialog</Button>*/}
      <Dialog open={react} onClose={() => setReact(false)}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            {" "}
            Are you sure to delete this App?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReact(false)}>No</Button>
          <Button onClick={() => handleDelete(props.allApps)}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Modal
        className="d-flex justify-content-center align-items-center"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Paper>
          <div style={{ minWidth: 500, minHeight: 370 }}>
            <div className="text-primary font-weight-bold font-size-h2 ml-10 mt-4 d-inline text-center minWidth-300 display-flex ">
              Assigned CouponsList
            </div>
            <Box mt={3}>
              <TextField
                style={{
                  borderColor: "light",
                  stroke: "#dcf3fc",
                  borderRadius: "50px",
                  borderWidth: "20",
                  borderStyle: "solid",
                  display: "block",
                  width: "100%",
                  padding: "10px 12px",
                }}
                name="name"
                placeholder="Name"
                label="Name"
                // onChange={handleChange}
                // value={values.name}
                required
              />
            </Box>
            <Box mt={2}>
              <TextField
                style={{
                  borderColor: "light",
                  stroke: "#dcf3fc",
                  borderRadius: "50px",
                  borderWidth: "20",
                  borderStyle: "solid",
                  display: "block",
                  width: "100%",
                  padding: "10px 12px",
                }}
                name="email"
                placeholder="Email"
                label="Email"
                required
              />
            </Box>
          </div>
        </Paper>
      </Modal>
      <PaginationProvider pagination={paginationFactory(PaginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <BootstrapTable
              mode="radio"
              size="sm"
              wrapperClasses="table-responsive "
              bordered={false}
              classes="table table-head-custom table-align-top-left cursor"
              bootstrap4
              hover={true}
              keyField="id"
              data={props.allApps === null ? [] : props.allApps}
              columns={columns}
              noDataIndication={
                <div
                  className="text-center"
                  style={{ color: "#212121", marginTop: "10px" }}
                >
                  No App Found
                </div>
              }
              selectRow={selectRow}
              defaultSorted={uiHelpers.defaultSorted}
              onTableChange={
                getHandlerTableChange()
                //applicationsUIProps.setQueryParams
              }
              filter={filterFactory()}
              {...paginationTableProps}
            >
              {/*<PleaseWaitMessage entities={entities} />*/}
              {/*<NoRecordsFoundMessage entities={entities} />*/}
            </BootstrapTable>
          );
        }}
      </PaginationProvider>
      <div className="text-right">
        <div>
          {/* <h4>Choose Retailer</h4> */}
          <DropdownButton
            fullWidth={true}
            alignLeft
            title={value}
            id="dropdown-basic-button"
            className="d-inline mt-6 mr-5"
            variant="secondary"
            onSelect={handleSelect}
          >
            {props.retailers && props.retailers.length > 0 ? (
              props.retailers.map((item, index) => (
                <Dropdown.Item eventKey={item.retailer.id}>
                  {item.name}
                </Dropdown.Item>
              ))
            ) : (
              <div>Loading ...</div>
            )}
          </DropdownButton>
          <button
            type="button"
            className="btn btn-success"
            data-toggle="modal"
            disabled={disable}
            onClick={handleApps}
            data-target="#exampleModalLong"
          >
            Assign
          </button>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  error: state.apps.error,
  success: state.apps.success,
  loading: state.apps.loading,
  allApps: state.apps.allApps,
  apps_assigning: state.apps.apps_assigning,
  retailers: state.retailer.getRetailers,
  auth: state.auth,
});

const mapActionsToProps = {
  FetchAllApps,
  AssigningApps,
  fetchRetailers,
  EditAppData,
  AppDelete,
};
export default connect(mapStateToProps, mapActionsToProps)(AllApps);