// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import moment from "moment";
//import { shallowEqual, useSelector } from "react-redux";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import { Pagination } from "../../../../_metronic/_partials/controls";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import SVG from "react-inlinesvg";
import { TextField, Grid } from '@material-ui/core';
import { ToastContainer, toast, Slide } from "react-toastify";

/* ========= redux router ========= */
import {connect} from 'react-redux';
import {useSelector} from 'react-redux';
import { fetchTotalBooking } from '../redux/bookingAction';
import { Link } from "react-router-dom";
import {Redirect} from 'react-router-dom';
import {useHistory} from 'react-router-dom';

/* ========= style for form ========= */
import "../../../../styles/applicationTable.scss";
import 'react-toastify/dist/ReactToastify.css';

const BookingTable=({auth,...props}) =>{
 const history=useHistory();
 

  useEffect(()=>{
    if(auth){
   props.fetchTotalBooking(auth.authToken);
    }
  },[auth])
  console.log("propssss",props)
   console.log("total Booking", props.bookings)
 
  
  // Table columns
  const columns = [
   
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },
    
    {
      dataField: "mobile",
      text: "Mobile",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },

    {
      dataField: "numberOfPersons",
      text: "Persons",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },

    {
      dataField: "visited",
      text: "Visited",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter()
    },
    {
      dataField: "meetingStartTime",
      text: "StartTime",
      sort: true,
     
      filter: textFilter(),
      formatter: (cell) => {
        return(
          <>
          {moment(cell).format("LL")}
          <br/>
          {moment(cell).format("hh:mm A")}</>
        )
      },
    },
    {
      dataField: "meetingEndTime",
      text: "EndTime",
      sort: true,
     // sortCaret: sortCaret,
      //   headerSortingClasses,
      filter: textFilter(),
      formatter: (cell) => {
        return(
          <>
          {moment(cell).format("LL")}
          <br/>
          {moment(cell).format("hh:mm A")}</>
        )
      },
    },
    
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    //totalSize: totalCount,
    totalSize: '0',
    //sizePerPageList: uiHelpers.sizePerPageList,
    //sizePerPage: applicationsUIProps.queryParams.pageSize,
    //page: applicationsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              //isLoading={listLoading}
              paginationProps={paginationProps}
            >
            
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-align-top-left cursor"
                bootstrap4
                hover={true}
              keyField="id"
                data={props.bookings === null ? [] : props.bookings}
                columns={columns}
                //defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  //applicationsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: applicationsUIProps.ids,
                //   setIds: applicationsUIProps.setIds,
                // })}
                filter={filterFactory()}
                {...paginationTableProps}
              >
                {/*<PleaseWaitMessage entities={entities} />*/}
                {/*<NoRecordsFoundMessage entities={entities} />*/}
              </BootstrapTable>

            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
const mapStateToProps = (state) => ({
  error: state.bookings.error,
  success: state.bookings.success,
  loading: state.bookings.loading,
  bookings: state.bookings.allBookings,
  auth:state.auth
})

const mapActionsToProps = {

  fetchTotalBooking

};
export default connect(mapStateToProps, mapActionsToProps)(BookingTable);
