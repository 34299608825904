import axios from "axios";
import __env from "../../../../env";
export function CreateOffer(formData) {
  return axios.post(
    `${__env.dataApi}/appointment/createAppointment`,
    formData,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
}

export function getALLOffers(token) {
  return axios.get(`${__env.dataApi}/adminDashboard/getAppointments`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
}
export function fetchOffers(appid) {
 
  return axios.get(`${__env.dataApi}/retailer/getRetailerByAppId/${appid}`);
}

export function AvailCoupon(token, formData) {
  // console.log(`${__env.dataApi}/couponCode/couponRedeem`);
  // console.log(
  //   "Scanned Data\n",
  //   scannedData.mobile + "\n" + scannedData.couponCode
  // );
  // const params = JSON.stringify({
  //   name: formData.name,
  //   couponCode: formData.couponCode,
  //   email:formData.email,
  //   mobile: formData.mobile,
  //   appId: formData.appId
  // });
  //  console.log(params);
  return axios.post(`${__env.dataApi}/couponCode/couponRedeem`, formData, {
    headers: {
      Authorization: `Bearer ${token || ""}`,
      "Content-Type": "application/json",
    },
  });
}