import React, { useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import CouponTable from "./coupon-table/CouponTable";
import { useDispatch } from "react-redux";


export function CouponCard() {
  
  return (
    <Card>
      <CardHeader title="All Coupons list">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CouponTable />
      </CardBody>
    </Card>
  );
}
