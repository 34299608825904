import { createSlice } from "@reduxjs/toolkit";

const initialOfferState = {
  loading: false,
  success: "",
  error: "",
  offer: null,
  allOffers: null,
  fetchOffer: null,
  redeemCoupon: null,
};

export const offerSlice = createSlice({
  name: "offer",
  initialState: initialOfferState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      state.loading = false;
    },
    catchSuccessMessage: (state, action) => {
      state.success = `${action.payload.success}`;
      state.loading = false;
    },
    startCall: (state) => {
      state.error = null;
      state.loading = true;
    },
    // coupon
    offerData: (state, action) => {
      console.log("action payload", action.payload);
      state.loading = false;
      state.offer = action.payload.offer;
      state.error = null;
    },
    offerFetched: (state, action) => {
      state.loading = false;
      state.fetchOffer = action.payload.offerResponse;
      state.error = null;
    },
    totalOffers: (state, action) => {
      console.log("total payload", action.payload);
      state.loading = false;
      state.allOffers = action.payload.offerResponse;
      state.error = null;
    },
    redeemedCoupon: (state, action) => {
      console.log("redeem coupon response", action.payload);
      state.loading = false;
      state.redeemCoupon = action.payload.offerResponse;
      state.error = null;
    },
  },
});