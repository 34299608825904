import React, { useMemo, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { Card, CardBody, Input } from "../../../_metronic/_partials/controls";
import { Box, Grid, Button, Typography } from "@material-ui/core";
import DatePicker from "react-datepicker";

/* ========= Related to the form ========= */
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Coupon } from "./redux/couponAction";

/* ========= style for form ========= */
import "../../../styles/createAppForm.scss";

const CreateCouponForm = ({ ...props }) => {
  const history = useHistory();
  const appId = props.appId;
  const handleCoupon = (values, { resetForm }) => {
    const formData = {
      ...values,
    };
    if (formData) {
      props
        .Coupon(formData, appId)
        .then((response) => {
          resetForm();
          values = {
            couponCode: "",
            // mobile: "",
            minPurchaseAmt: "",
            discountPercent: "",
          };
          toast.success("Coupon created successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
          setTimeout(() => {
            history.push(`/allcoupons`);
          }, 1000);
        })
        .catch((err) => {
          toast.error("Something went wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        });
    }
  };

  return (
    <Box p={2}>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        validationSchema={Yup.object().shape({
          couponCode: Yup.string().required("couponCode is required."),
          // mobile: Yup.string().required("Mobile number is required."),
          minPurchaseAmt: Yup.string().required(
            "Minimum purchase amount is required."
          ),
          discountPercent: Yup.string().required(
            "Discount percentage is required."
          ),
        })}
        onSubmit={handleCoupon}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                {/* <Box>
                 <Field
                    name="email"
                    component={Input}
                    placeholder="Email"
                    label="Email"
                    onChange={handleChange}
                    value={values.email}
                    withFeedbackLabel={false}
                    required
                  />
                </Box>

                <Box mt={2}>
                  <Field
                    name="mobile"
                    component={Input}
                    placeholder="Mobile"
                    label="Mobile Number"
                    onChange={handleChange}
                    value={values.mobile}
                    withFeedbackLabel={false}
                    required
                  />
        </Box>*/}

        <Box mt={2}>
        <Field
          name="couponCode"
          component={Input}
          placeholder="couponCode"
          label="couponCode"
          onChange={handleChange}
          value={values.couponCode}
          withFeedbackLabel={false}
          required
        />
      </Box>
                <Box mt={2}>
                  <Field
                    name="minPurchaseAmt"
                    component={Input}
                    placeholder="Minimum purchase amount"
                    label="Minimum Purchase Amount"
                    onChange={handleChange}
                    value={values.minPurchaseAmt}
                    withFeedbackLabel={false}
                    required
                  />
                </Box>
                <Box mt={2}>
                  <Field
                    name="discountPercent"
                    component={Input}
                    placeholder="Discount percentage"
                    label="Discount Percentage"
                    onChange={handleChange}
                    value={values.discountPercent}
                    withFeedbackLabel={false}
                    required
                  />
                </Box>

                <Box mt={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn btn-primary btn-elevate"
                  >
                    Add Coupon
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  error: state.calendar.error,
  success: state.calendar.success,
  loading: state.calendar.loading,
  app: state.calendar.booking,
  auth: state.auth,
});

const mapActionsToProps = {
  Coupon,
};
export default connect(mapStateToProps, mapActionsToProps)(CreateCouponForm);
