import * as requestFromServer from "./appCrud";
import { appSlice } from "./appSlice";
import { getErrorMessage } from "../../../utils";
import { reject } from "lodash";

const { actions } = appSlice;

export const App = (token, data) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    return requestFromServer
      .createApp(token, data)
      .then((response) => {
        const appResponse = response.data;
        dispatch(actions.appData({ appResponse }));
        resolve(response);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};
export const EditAppData = (data) => (dispatch) => {
  dispatch(actions.EditData(data));
};

export const RemoveImage = (token,file,appId) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    return requestFromServer
      .DeleteImage(token,file,appId)
      .then((response) => {
      
        resolve(response);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};
export const EditApps = (token, formData, appId) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    return requestFromServer
      .EditApp(token, formData, appId)
      .then((response) => {
        const appResponse = response.data;
        dispatch(actions.AllAppsEdit({ appResponse }));
        resolve(response);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};

export const FetchAllRetailerApps = (id) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .getAllApps(id)
    .then((response) => {
      const appResponse = response.data;
      dispatch(actions.appsFetched({ appResponse }));
      console.log("response", response);
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const FetchAllApps = (token) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .fetchAppsList(token)
    .then((response) => {
      const appResponse = response.data;
      dispatch(actions.appsList({ appResponse }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const FetchAllCategories = (token) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .GetAllCategories(token)
    .then((response) => {
      const appResponse = response.data;
      dispatch(actions.appCategory({ appResponse }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const FetchAllPages = (token, category) => (dispatch) => {
  dispatch(actions.startCall());
  return requestFromServer
    .GetAllPages(token, category)
    .then((response) => {
      const appResponse = response.data;
      dispatch(actions.fetchLandingPage({ appResponse }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const AssigningApps = (token, appId, id) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    return requestFromServer
      .AppsAssigning(token, appId, id)
      .then((response) => {
        const appResponse = response.data;
        dispatch(actions.assigingAppsToRetailer({ appResponse }));
        resolve(response);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};

// Apps Delete Api

export const AppDelete = (token, appId) => (dispatch) => {
  //alert("response");
  return new Promise(function(resolve, reject) {
    dispatch(actions.startCall());
    return requestFromServer
      .DeleteApp(token, appId)

      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(actions.catchError({ error: errorMessage }));
        reject(error);
      });
  });
};