import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

import Dashboard from "./Dashboard/Dashboard";
import { CardBody } from "../../../../_metronic/_partials/controls";
import { BookingsTable } from "../../../components/RetailerApplications/BookingsTable";
import DatePickers from "../../../components/RetailerApplications/DatePickers"
import CouponsTable from "../../../components/RetailerApplications/CouponsTable";
import { useParams } from "react-router-dom";

import Demo1Dashboard from '../../../../_metronic/_partials/dashboards/Demo1Dashboard';
import AssignedCoupons from "../../../components/RetailerApplications/AssignedCoupons";
import AvailedCoupons from "../../../components/RetailerApplications/AvailedCoupons";
import AssignedButNotAvailedCoupons from "../../../components/RetailerApplications/AssignedButNotAvailedCoupons";

/* ========= material ui ========= */
import { Button, Card } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import { AppBar, Tabs, Tab, Typography, Grid } from "@material-ui/core";

/* ========= redux ========= */
import { connect } from "react-redux";
import {
  fetchAppDetail,
  DatesData,
  fetchAppBooking,
  fetchAppCoupon,

} from "../_redux/organizationsActions";
import { fetchInsightsGraph } from "../../../pages/Applications/_redux/organizationsActions";

/* ========= styles ========= */
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Dashboard/Dashboard.module.scss";


var campaignStart = {
  "28bd8516-9d60-4694-97f5-7534c6364321": "2022-07-01",
  "f13dbe0f-72ee-4df3-8e65-2e54584895ad": "2022-09-01"
}
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: "100%",
  },
}));

function FullWidthTabs({ fetchAppDetail,
  DatesData,
  applications,
  auth,
  fetchInsightsGraph,
  fetchAppBooking,
  fetchAppCoupon,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [startDate, setStartDate] = useState(new Date("2022-07-01"));
  const [endDate, setEndDate] = useState(new Date());

  const startdate = moment(startDate).format("YYYY-MM-DD")
  const enddate = moment(endDate).format("YYYY-MM-DD")
 const { id } = useParams();


  useEffect(() => {
    DatesData(startdate, enddate)
    fetchAppDetail(startdate, enddate, id)
  }, [startdate, enddate, id])

  const handleSubmit = () => {
    DatesData(startdate, enddate)

    fetchAppDetail(startdate, enddate,  id).then(response => {
      toast.success("Fetching data successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 })
    })
      .catch(err => {
        const errorDetails = err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong";
        toast.error(errorDetails, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 })
      })

  }

  function handleChange(event, newValue) {
    setValue(newValue);
  }
  function handleChangeIndex(index) {
    setValue(index);
  }

  useEffect(() => {

    if (auth.authToken) {
      fetchAppBooking(id, auth.authToken);
      fetchAppCoupon(id, auth.authToken);
      fetchAppDetail(id, auth.authToken, startdate, enddate);
    }

  }, [auth.authToken, startdate, enddate]);

  return (
    <div className={classes.root}>
      {/* {JSON.stringify(applications)} */}
      <AppBar position="static" color="default" full>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          style={{ backgroundColor: "#f0f8ff", color: "#091f26" }}
        >
           <Tab label="Dashboard" />
       {/*<Tab label="Bookings" />*/}
          <Tab label="Coupons" />

          <Tab label="Redeemed Coupons"/>
 {/* <Tab label="AssignedButNotAvailed"/>*/}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
         <TabContainer dir={theme.direction}>
          <div className={styles.datepicker}>
            <div className={styles.date}> 
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              value={startDate}
              className={styles.starttime}/>
          
             <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              value={endDate}
              className={styles.endtime} />
              </div>

            <div className={styles.apply_button}> 
            <button onClick={handleSubmit}
            variant="contained">Apply</button></div>

          </div>


          <div style={{ padding: 24 }}>
            {applications.appDetail && (
              <Dashboard appDetail={applications.appDetail || {}} />
            )}
          </div>
            </TabContainer>


        {/* <TabContainer dir={theme.direction}>
          <Card>
            <CardBody>
              <BookingsTable appBooking={applications?.appBooking || []} />
            </CardBody>
          </Card>
            </TabContainer>*/}

        <TabContainer dir={theme.direction}>
          <Card sx={{ padding: "20px" }}>
            <CardBody>
              <CouponsTable />
            </CardBody>
          </Card>
        </TabContainer>

        {/* <TabContainer dir={theme.direction}>
       <div style={{ padding: 24 }}>
        <Demo1Dashboard />
        
        </div>
          </TabContainer>*/}
        {/* <TabContainer dir={theme.direction}>
        <Card>
          <CardBody>
            <AssignedCoupons />
          </CardBody>
        </Card>
          </TabContainer>
      <TabContainer dir={theme.direction}>
      <Card>
        <CardBody>
          <AvailedCoupons />
        </CardBody>
      </Card>
    </TabContainer>*/}
    <TabContainer dir={theme.direction}>
    <Card>
      <CardBody>
        <AvailedCoupons />
      </CardBody>
    </Card>
          </TabContainer>
      </SwipeableViews>
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    applications: state.applications,
    auth: state.auth,

  };
};

const mapActionsToProps = {
  fetchAppDetail,
  fetchInsightsGraph,
  fetchAppBooking,
  fetchAppCoupon,
  DatesData,
  fetchInsightsGraph
};

export default connect(mapStateToProps, mapActionsToProps)(FullWidthTabs);