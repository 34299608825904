import React, { useMemo, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import CreateUserForm  from "./create-user-form/CreateUserForm";
import { useDispatch } from "react-redux";

export function CreateUserPage() {
  
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader title="Create Retailer">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CreateUserForm />
      </CardBody>
    </Card>
  );
}
