import axios from "axios";
import env from "../../../../env/index";

const api_url = `${env.dataApi}`;

export const LOGIN_URL =`${api_url}/auth/login`;
export const REGISTER_URL = `${api_url}/auth/guest/register`;
export const REQUEST_PASSWORD_URL = `${api_url}/resetpassword`;

export const ME_URL = `${api_url}/profile`;

export function login(username, password) {
  console.log("Login", LOGIN_URL);
  return axios.post(LOGIN_URL, { username, password });
}

export function register(userData) {
  return axios.post(REGISTER_URL, userData);
}

export function requestPassword(mobile) {
  return axios.post(`${REQUEST_PASSWORD_URL}/${mobile}`, { mobile });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}