import axios from "axios";
import __env from "../../../../env";


// READ  =>  GET: retailer apps on login to the retailer dashbaord
export function getApplicationDetail(id, token) {
  return axios.get(`${__env.dataApi}/apps/appByAppId/${id}`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
};

// READ  =>  GET: retailer  social insights(facebook,youtube,instagram) data for retailer dashboard
export function getApplicationDetailData( startdate, enddate,appId) {
  return axios.get(
    `${__env.dataApi}/couponCode/socialMediaInsights?startDate=${startdate}&endDate=${enddate}&appId=${appId}`,
   );
};

// READ  =>  GET: facebook graph list(impressions,reach,conversions)
export function InsightsGraph(startdate, enddate,appId) {
  return axios.get(
    `${__env.dataApi}/couponCode/insights?startDate=${startdate}&endDate=${enddate}&appId=${appId}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export function YouTubeInsightsGraph(startdate, enddate,appId) {
  return axios.get(
    `${__env.dataApi}/couponCode/youTubeInsightsGraph?startDate=${startdate}&endDate=${enddate}&appId=${appId}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export function getApplicationDetailBooking(id, token) {
  return axios.get(`${__env.dataApi}/appointment/getByAppId/${id}`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
};

export function getApplicationDetailCoupons(id, token) {
  return axios.get(`${__env.dataApi}/couponCode/getCoupon/${id}`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
};

export function getAssignedCoupons(id) {
  return axios.get(`${__env.dataApi}/couponCode/getAssignCoupon/${id}`);
};

export function getAvailedCoupons(id) {
  return axios.get(`${__env.dataApi}/couponCode/availed/${id}`);
};

export function getAssignedButNotAvailedCoupons(id) {
  return axios.get(`${__env.dataApi}/couponCode/notAvailed/${id}`);
};


export function getApplicationGraphData(id, token) {
  return axios.get(`${__env.dataApi}/appointmentData/?appId=${id}`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
};

export function getCouponGraphData(id, token) {
  return axios.get(`${__env.dataApi}/couponData/?appId=${id}`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
};

export function getAssignedCouponGraph(id, token) {
  return axios.get(`${__env.dataApi}/assignGraphData/?appId=${id}`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
};

export function getAvailedCouponGraph(id, token) {
  return axios.get(`${__env.dataApi}/availedData/?appId=${id}`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
};

export function getNotAvailedCouponGraph(id, token) {
  return axios.get(`${__env.dataApi}/notAvailGraphData/?appId=${id}`, {
    headers: { Authorization: `Bearer ${token || ""}` },
  });
};

 export const ORGANIZATIONS_URL = "organizations";

// CREATE =>  POST: add a new organization to the server
export function createOrganization(organization) {
  return axios.post(ORGANIZATIONS_URL, organization);
}

// READ
export function getAllOrganizations() {
  return axios.get(ORGANIZATIONS_URL);
}

export function getOrganizationById(organizationId) {
  return axios.get(`${ORGANIZATIONS_URL}/${organizationId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
// export function findOrganizations(queryParams) {
//   const { filter, pageSize, sortField, sortOrder } = queryParams;
//   const pageNumber = +queryParams.pageNumber - 1;
//   return axios.post(`${ORGANIZATIONS_URL}/find`, filter, {
//     params: {
//       pageNumber, pageSize, sortField, sortOrder
//     }
//   });
// }

export function findOrganizations() {
  return axios.get(`${ORGANIZATIONS_URL}`);
}
// UPDATE => PUT: update the organization on the server
export function updateOrganization(id, organization) {
  return axios.put(`${ORGANIZATIONS_URL}/${id}`, organization);
}

// UPDATE Status
export function updateStatusForOrganizations(ids, status) {
  return axios.post(`${ORGANIZATIONS_URL}/updateStatusForOrganizations`, {
    ids,
    status,
  });
}

// DELETE => delete the organization from the server
export function deleteOrganization(organizationId) {
  return axios.delete(`${ORGANIZATIONS_URL}/${organizationId}`);
}

// DELETE Organizations by ids
export function deleteOrganizations(ids) {
  return axios.post(`${ORGANIZATIONS_URL}/deleteOrganizations`, { ids });
}

export function findOrganisationByDomain(domain) {
  return axios.get(`${__env.dataApi}/${ORGANIZATIONS_URL}/domain`, {
    params: {
      domain: domain,
    },
  });
}

export function findOrganisationInfo() {
  return axios.get(`${__env.dataApi}/info`);
}
