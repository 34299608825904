import React, { useMemo, useEffect, useState, useRef } from "react";
import moment from "moment";
import {
  Card,
  CardBody,
  Input,
} from "../../../../_metronic/_partials/controls";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

/* ========= Related to the form ========= */

import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { Formik, Field, FieldArray, Form } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
/* ========= Material UI icons ========= */
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

/* ========= react-redux ========= */
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import {
  App,
  FetchAllCategories,
  FetchAllPages,
} from "../../../pages/AppList/redux/appAction";

/* ========= style for form ========= */
import classes from "../../../../styles/createAppForm.scss";

const CreateAppForm = ({ auth, ...props }) => {
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [date, setDate] = useState(null);
  const [openTime, setOpenTime] = useState(new Date());
  const [closeTime, setCloseTime] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [landingPage, setLandingPage] = useState("");
  const [category, setCategory] = useState("Choose Category");
  const [id, setId] = useState("");
  const filter = createFilterOptions();
  const history = useHistory();
  useEffect(() => {
    if (auth) {
      props.FetchAllCategories(auth.authToken);
    }
  }, [auth]);

  const handleFile1 = (event) => {
    if (event) {
      setImage1(event.target.files[0]);
    }
  };
  const handleFile2 = (event) => {
    if (event) {
      setImage2(event.target.files[0]);
    }
  };
  const handleFile3 = (event) => {
    if (event) {
      setImage3(event.target.files[0]);
    }
  };

  const handleSelect = (eventKey) => {
    const data = props.allCategories.filter(
      (item) => item.categoryName === eventKey
    );
    const categoryId = data[0]?.categoryId;
    setId(categoryId);
    setCategory(eventKey);

    props.FetchAllPages(auth.authToken, categoryId);
  };
  const handleSelectedPage = (e) => {
    setLandingPage(e);
  };

  const handleApp = (values) => {
    const opentime = moment(openTime).format("hh:mm A");
    const closetime = moment(closeTime).format("hh:mm A");

    if (values) {
      const formData = new FormData();
      formData.append("image1", image1);
      formData.append("image2", image2);
      formData.append("image3", image3);
      formData.append("socialMediaLinks", JSON.stringify(values.Links));
      formData.append("appName", values["appName"]);
      formData.append("tagLine", values["tagLine"]);
      formData.append("description", values["description"]);
      formData.append("address", values["address"]);
      formData.append("openTime", opentime);
      formData.append("closeTime", closetime);
      formData.append("category", id);
      formData.append("landingPage", landingPage);

      setLoading(true);
      props
        .App(auth.authToken, formData)
        .then((response) => {
          toast.success("App created successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
          history.push("/apps");
          setLoading(false);
        })
        .catch((err) => {
          const errorDetails = err?.response?.data?.message
            ? err.response.data.message
            : "Something went wrong";
          toast.error(errorDetails, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
          setLoading(false);
        });
    }
  };

  return (
    <Box p={2}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          Links: [{ type: "", url: "" }],
        }}
        validationSchema={Yup.object().shape({
          // appName: Yup.string().required("App Name is required."),
          // tagLine: Yup.string().required("Tag Line is required."),
          // description: Yup.string().required("Description is required."),
          // files: Yup.array().of(
          //   Yup.object().shape({
          //     multipartFile: Yup.string().required("File is required"),
          //   }))
          //   .min(1, 'Must have at least one Image')
          //   .max(3, 'That is too many images'),
          // openTime: Yup.string().required("OpenTime is required."),
          // closeTime: Yup.string().required("Close Time is required."),
          // address: Yup.string().required("Address is required")
        })}
        onSubmit={handleApp}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          setFieldValue,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={10}>
              <Grid item xs={12} sm={12} md={6}>
                <Box>
                  <Field
                    name="appName"
                    component={Input}
                    placeholder="AppName"
                    label="AppName"
                    onChange={handleChange}
                    value={values.appName}
                    withFeedbackLabel={false}
                    required
                  />
                </Box>

                <Box mt={2}>
                  <Field
                    name="tagLine"
                    component={Input}
                    placeholder="Tagline"
                    label="Tagline"
                    onChange={handleChange}
                    value={values.tagLine}
                    withFeedbackLabel={false}
                    required
                  />
                </Box>
                <Box mt={2}>
                  <Field
                    name="description"
                    component={Input}
                    placeholder="Description"
                    label="Description"
                    onChange={handleChange}
                    value={values.description}
                    withFeedbackLabel={false}
                    required
                  />
                </Box>
                <Box mt={2}>
                  <Field
                    name="address"
                    component={Input}
                    placeholder="Address"
                    label="Address"
                    onChange={handleChange}
                    value={values.address}
                    withFeedbackLabel={false}
                    required
                  />
                </Box>

                <Box mt={2}>
                  <Typography>Banner Image</Typography>
                  <Field
                    input
                    label="Image"
                    type="file"
                    className=" mt-2 border rounded p-2 w-100"
                    name="image1"
                    onChange={handleFile1}
                    required
                  />
                </Box>

                <Box mt={2}>
                  <Typography>App Logo</Typography>
                  <Field
                    input
                    label="Image"
                    type="file"
                    name="image2"
                    className=" mt-2 border border-secondary rounded p-2 w-100"
                    onChange={handleFile2}
                  />
                </Box>
                <Box mt={2}>
                  <Typography>Image3</Typography>
                  <Field
                    input
                    label="Image"
                    type="file"
                    name="image3"
                    className=" mt-2 border border-secondary rounded  p-2 w-100"
                    onChange={handleFile3}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Typography>Select Open and CloseTime</Typography>
                <div className="d-flex">
                  <div className="w-100 mr-2">
                    <DatePicker
                      selected={openTime}
                      className="mb-2  mt-2 border border-secondary rounded p-3 w-100"
                      onChange={(date) => setOpenTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </div>
                  <div className="w-100">
                    <DatePicker
                      selected={closeTime}
                      onChange={(date) => setCloseTime(date)}
                      // className="od__textField datepicker"
                      className="mb-2  mt-2 border border-secondary rounded p-3 w-100"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </div>
                </div>

                <Box mt={2}>
                  <Typography>Choose Category</Typography>

                  <DropdownButton
                    fullWidth={true}
                    alignLeft
                    title={category}
                    id="dropdown-basic-button"
                    className="d-inline mt-6 mr-5 w-100"
                    variant="secondary"
                    onSelect={handleSelect}
                    onClick={() => {
                      setLandingPage("Choose LandingPage");
                    }}
                  >
                    {props.allCategories ? (
                      props.allCategories.map((item, index) => (
                        <Dropdown.Item eventKey={item.categoryName}>
                          {item.categoryName}
                        </Dropdown.Item>
                      ))
                    ) : (
                      <div>No Options</div>
                    )}
                  </DropdownButton>

                  {category && (
                    <DropdownButton
                      title={landingPage}
                      id="dropdown-basic-button"
                      // align={{ lg: 'end' }}
                      className="d-inline mt-6 mr-5 w-100"
                      variant="secondary"
                      onSelect={handleSelectedPage}
                    >
                      {props.allPages ? (
                        props.allPages.map((item, index) => (
                          <Dropdown.Item eventKey={item.name}>
                            {item.name}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <div>Loading ...</div>
                      )}
                    </DropdownButton>
                  )}
                </Box>

                <Box mt={2}>
                  <FieldArray
                    name="Links"
                    render={(arrayHelpers) => (
                      <div>
                        <Grid item>
                          <Typography>
                            Add Icons<span style={{ color: "red" }}></span>
                          </Typography>
                        </Grid>

                        {/* Add a new empty item at the end of the list */}
                        {values.Links &&
                          values.Links.length > 0 &&
                          values.Links.map((value, index) => (
                            <div key={index} style={{ display: "flex" }}>
                              <Select
                                displayEmpty
                                className=" mb-2 mr-2  mt-2 border border-secondary rounded w-50 "
                                name={`Links[${index}].type`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              >
                                <MenuItem value={"facebook"}>Facebook</MenuItem>
                                <MenuItem value={"instagram"}>
                                  Instagram
                                </MenuItem>
                                <MenuItem value={"youtube"}>YouTube</MenuItem>
                              </Select>

                              <Field
                                type="url"
                                name={`Links[${index}].url`}
                                className="mb-2 mt-2 border border-secondary rounded w-100"
                                placeholder="URL"
                                onChange={handleChange}
                                required
                              />
                              <button
                                type="button"
                                className="mr-1 ml-6 mt-4 mb-4"
                                onClick={() => arrayHelpers.remove(index)} // remove a file from the list
                              >
                                -
                              </button>
                            </div>
                          ))}
                        <button
                          type="button"
                          variant="contained"
                          className="btn btn-secondary mt-2 "
                          onClick={() => arrayHelpers.push("")}
                        >
                          Add SocialIcons
                        </button>
                      </div>
                    )}
                  />
                </Box>

                <Box mt={2}>
                  <Button
                    color="primary"
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    endIcon={
                      loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        ""
                      )
                    }
                  >
                    Add Retailer App
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  error: state.calendar.error,
  success: state.calendar.success,
  loading: state.calendar.loading,
  app: state.calendar.booking,
  allCategories: state.apps.allCategories,
  allPages: state.apps.allPages,
  auth: state.auth,
});

const mapActionsToProps = {
  App,
  FetchAllCategories,
  FetchAllPages,
};
export default connect(mapStateToProps, mapActionsToProps)(CreateAppForm);