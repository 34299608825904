import React, { useEffect, useState } from "react";

import {
  LineChart,
  AreaChart,
  Area,
  Line,
  Brush,
  XAxis,
  Legend,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchYouTubeInsightsGraph } from "../../pages/Applications/_redux/organizationsActions";

const YouTubeChart = ({ auth, ...props }) => {
  const { id } = useParams();
  const startdate = useSelector((state) => state.applications.startDate);
  const enddate = useSelector((state) => state.applications.endDate);
  const dateFormatter = (dates) => {
    return moment(dates).format("MMM D");
  };

  useEffect(() => {
    props.fetchYouTubeInsightsGraph(startdate, enddate, id);
  }, [startdate, enddate, id]);
  return (
    <div>
      <ResponsiveContainer width="100%" height={370}>
        <LineChart
          width={730}
          height={250}
          data={props.youtubeinsights}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="startDate" tickFormatter={dateFormatter} />
          <YAxis type="number" domain={[0, "dataMax"]} />
          <Tooltip />
          <Legend />

          <Line
            type="monotone"
            dataKey="views"
            stroke="#f92458"
            fill="orange"
          />
          <Line
            type="monotone"
            dataKey="impressions"
            stroke="#82ca9d"
            fill="#82ca9d"
          />
          <Line
            type="monotone"
            dataKey="clicks"
            stroke="#375a9d"
            fill="#8884d8"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
const mapStateToProps = (state) => ({
  error: state.applications.error,
  success: state.applications.success,
  loading: state.applications.loading,
  youtubeinsights: state.applications.youtubeinsights,

  auth: state.auth,
});

const mapActionsToProps = {
  fetchYouTubeInsightsGraph,
};
export default connect(mapStateToProps, mapActionsToProps)(YouTubeChart);
