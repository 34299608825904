
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
//import { shallowEqual, useSelector } from "react-redux";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { Pagination } from "../../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { TextField, Grid } from "@material-ui/core";
import { ToastContainer, toast, Slide } from "react-toastify";
import moment from "moment";

/* ========= Redux,router ========= */
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAllCoupons } from "../redux/couponAction";
/* ========= style for form ========= */
import "../../../../styles/applicationTable.scss";
import "react-toastify/dist/ReactToastify.css";

const CouponTable = ({ auth, ...props }) => {
  const history = useHistory();


  useEffect(() => {
    if (auth) {
      props.fetchAllCoupons(auth.authToken);
    }
  }, [auth]);

  const columns = [
   
    {
      dataField: "couponCode",
      text: "CouponCode",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },
    {
      dataField: "minPurchaseAmt",
      text: "MinPurchaseAmt",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },
    
    {
      dataField: "discountPercent",
      text: "DiscountPercent",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      filter: textFilter(),
    },

    {
      dataField: "generatedDate",
      text: "GeneratedDate",
      sort: true,
     // sortCaret: sortCaret,
     // headerSortingClasses,
      filter: textFilter(),
      formatter: (cell) => {
        return (
          <>
            {moment(cell).format("LL")}
            <br />
            {moment(cell).format("hh:mm A")}
          </>
        );
      },
    },
    {
      dataField: "expireDate",
      text: "ExpireDate",
      sort: true,
     // sortCaret: sortCaret,
     // headerSortingClasses,
      filter: textFilter(),
      formatter: (cell) => {
        return (
          <>
            {moment(cell).format("LL")}
            <br />
            {moment(cell).format("hh:mm A")}
          </>
        );
      },
    },
  ];
  // Table pagination properties
  const paginationOptions={
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            // <Pagination
            //   //isLoading={listLoading}
            //   paginationProps={paginationProps}
            // >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-align-top-left cursor"
                bootstrap4
               hover={true}
                keyField="id"
                data={props.coupons === null ? [] : props.coupons}
                columns={columns}
                noDataIndication={<div className='text-center' style={{color:'#212121',marginTop:"10px"}}>No Coupon Found</div>}
                //defaultSorted={uiHelpers.defaultSorted}
                onTableChange={
                  getHandlerTableChange()
                  //applicationsUIProps.setQueryParams
                }
                // selectRow={getSelectRow({
                //   entities,
                //   ids: applicationsUIProps.ids,
                //   setIds: applicationsUIProps.setIds,
                // })}
                filter={filterFactory()}
                {...paginationTableProps}
              >
                {/*<PleaseWaitMessage entities={entities} />*/}
                {/*<NoRecordsFoundMessage entities={entities} />*/}
              </BootstrapTable>
            // </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};
const mapStateToProps = (state) => ({
  error: state.coupons.error,
  success: state.coupons.success,
  loading: state.coupons.loading,
  coupons: state.coupons.getAllCoupons,
  auth: state.auth,
});

const mapActionsToProps = {
  fetchAllCoupons,
};
export default connect(mapStateToProps, mapActionsToProps)(CouponTable);
