import React, { useEffect, useState } from "react";
import { Modal, Paper } from "@material-ui/core";

import { QRCodeSVG } from "qrcode.react";
import { Formik, Field } from "formik";
import { CircularProgress, Box, Typography, Card } from "@material-ui/core";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Input } from "../../../../_metronic/_partials/controls";
import { Offer, fetchOfferData } from "../redux/offerAction";
import __env from "../../../../env";
import { useHistory } from "react-router-dom";
import "../../../../styles/landingPage.scss";

toast.configure();

const OfferDetails = ({ ...props }) => {
  const history = useHistory();
  const { intl } = props;
  const { appid, couponCode } = useParams();
  const [bookingModal, setBookingModal] = useState(false);
  console.log("appid", appid);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const OpenTime = props.getOffer ? props.getOffer.app.openTime : "";
  const CloseTime = props.getOffer ? props.getOffer.app.closeTime : "";

  const openTime = new Date(OpenTime).toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
    timeZone: "Asia/Kolkata",
  });
  const closeTime = new Date(CloseTime).toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
    timeZone: "Asia/Kolkata",
  });

  const date = new Date();
  const formatedDate = (value) => {
    let date = new Date(value);
    return (
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2) +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2) +
      ":" +
      ("00" + date.getSeconds()).slice(-2)
    );
  };
  const handleOffer = (values) => {
    const startDateStr = formatedDate(values.startTime);
    const endDateStr = formatedDate(values.endTime);

    if (startDateStr > endDateStr) {
      alert("End date need to be bigger then start date");
    }
    const formData = {
      ...values,
      startTime: startDateStr,
      endTime: endDateStr,
      appId: appid,
      couponCode: couponCode,
    };
    if (formData) {
      setLoading(true);
      props
        .Offer(formData)
        .then((response) => {
          toast.success("Offer created successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
        })
        .catch((error) => {
          toast.error("Something went wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
          setLoading(false);
        });
    }
  };

  const renderS3Content = ({ ...props }) => {
    let landingURL = document.getElementById("hLandingPageUrl").value;
    // alert(landingURL);
    // if (appid == "efcf7d4a-c665-4264-96ba-fc00a74dd610") {
    //   landingURL = "http://localhost:3000/media/templates/s3/01/index.html";
    // } else {
    //   landingURL = "http://localhost:3000/media/templates/s3/01/index.html";
    // }
    //landingURL = "http://localhost:3000/media/templates/s3/06/index.html";
    fetch(landingURL)
      .then(function(response) {
        return response.text();
      })
      .then(function(string) {
        //const logoUrl = props?.getOffer?.user?.retailer?.retailerImage;

        const socialMediaLinks = JSON.parse(
          document.getElementById("hSocialMediaLinks").value
        );

        document.getElementById("s3root").innerHTML = string
          .replace(/{{Your Name}}/g, "Sunil Bansal")
          .replace(
            /{{retailerImage}}/g,
            document.getElementById("hRetailerImage").value
          )
          .replace(
            /{{logo}}/g,
            "<img src='" +
              document.getElementById("hLogo").value +
              "' style='display: block; height: auto; border: 0; width: 60px; max-width: 100%;' />"
          )
          .replace(/{{tagline}}/g, document.getElementById("hTagLine").value)
          .replace(
            /{{description}}/g,
            document.getElementById("hDescription").value
          )
          .replace(/{{address}}/g, document.getElementById("hAddress").value)
          .replace(/{{phone}}/g, document.getElementById("hPhone").value)
          .replace(
            /{{fbLink}}/g,
            socialMediaLinks
              .filter((item) => item.type == "facebook")
              .map((item) => item.url)
          )
          .replace(
            /{{instaLink}}/g,
            socialMediaLinks
              .filter((item) => item.type == "instagram")
              .map((item) => item.url)
          )
          .replace(
            /{{retailerImage}}/g,
            document.getElementById("hRetailerImage").value
          )
          .replace(/{{timings}}/g, document.getElementById("hTimings").value)

          .replace(
            /{{couponCode}}/g,
            `
              <canvas
                    id="textCanvas"
                    style="
                      background-color: #e5e5f7;
                      opacity: 0.8;
                      background: linear-gradient(
                            135deg,
                            #ebbdb155 25%,
                            transparent 25%
                          ) -10px 0/ 20px 20px,
                        linear-gradient(
                            225deg,
                            #ebbdb1 25%,
                            transparent 25%
                          ) -10px 0/ 20px 20px,
                        linear-gradient(
                            315deg,
                            #ebbdb155 25%,
                            transparent 25%
                          )
                          0px 0/ 20px 20px,
                        linear-gradient(
                            45deg,
                            #ebbdb1 25%,
                            #e5e5f7 25%
                          )
                          0px 0/ 20px 20px;
                    "
                ></canvas>
                <img id="image" style="visibility: hidden; width: 1px;" />
          `
          );

        setTimeout(() => {
          document
            .getElementById("qrcode-holder")
            .appendChild(document.getElementById("qrcode-content"));
          document.getElementById("qrcode-content").style.visibility =
            "visible";
        }, 500);
        setTimeout(() => {
          generateCaptcha(couponCode);
        }, 1000);

        // if (document.readyState === "complete") {
        // setTimeout(() => {
        //   window.print();
        //  },1000);
        // } else {
        //   window.addEventListener('load', function(){
        //     setTimeout(() => {
        //       window.print();
        //      },2000);
        //   });
        // }
      });
  };

  useEffect(() => {
    props.fetchOfferData(appid);
    console.log("dattata", props);
    setTimeout(() => {
      renderS3Content();
    }, 1000);
  }, []);

  const links = props.getOffer ? props.getOffer.app.socialMediaLinks : "";
  console.log(links);

  function generateCaptcha(cText) {
    var tCtx = document.getElementById("textCanvas").getContext("2d"), //Hidden canvas
      imageElem = document.getElementById("image"); //Image element
    tCtx.canvas.width = 200; //tCtx.measureText(cText).width * 3.8;
    tCtx.canvas.height = 45;
    tCtx.font = "26px Arial";
    tCtx.fillText(cText, 15, 35);
    imageElem.src = tCtx.canvas.toDataURL();
    // console.log(imageElem.src);
  }
  // const socialLinks = JSON.parse(links)
  return (
    <div>
      {!success ? (
        <div>
          <input
            id="hLogo"
            type="hidden"
            value={props?.getOffer?.retailer?.logo}
          />
          <input
            id="hTagLine"
            type="hidden"
            value={props?.getOffer?.app?.tagLine}
          />
          <input
            id="hAddress"
            type="hidden"
            value={props?.getOffer?.app?.address}
          />
          <input
            id="hPhone"
            type="hidden"
            value={props?.getOffer?.retailer?.retailerMobileNumber}
          />
          <input
            id="hSocialMediaLinks"
            type="hidden"
            value={props?.getOffer?.app?.socialMediaLinks}
          />
          <input
            id="hDescription"
            type="hidden"
            value={props?.getOffer?.app?.description}
          />
          <input
            id="hLandingPageUrl"
            type="hidden"
            value={props?.getOffer?.app?.landingPageUrl}
          />
          <input
            id="hRetailerImage"
            type="hidden"
            value={props?.getOffer?.retailer?.retailerImage}
          />
          <input
            id="hTimings"
            type="hidden"
            value={`From ${openTime} to ${closeTime}`}
          />
          <div id="s3root"></div>
          <div id="qrcode-content" style={{ visibility: "hidden" }}>
            <QRCodeSVG
              value={JSON.stringify({
                appId: appid,
                couponcode: couponCode,
              })}
              size={300}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={true}
            />
          </div>
          <Modal
            className="d-flex justify-content-center align-items-center w-300 h-300"
            open={bookingModal}
            onClose={() => setBookingModal(false)}
          >
            <Paper>
              <div
                style={{
                  width: "400px",
                  margin: "30px auto",
                  textAlign: "center",
                }}
              >
                <div
                  style={{ margin: "10px auto 40px auto", color: "#4caf50" }}
                >
                  <h3>SUCCESS - Coupon Assigned!</h3>
                </div>
                <div id="msgThanks">
                  Thank you for claiming your coupon!
                  <br />
                  Check your email for more details.
                </div>
                <div style={{ display: "none" }}>
                  <h4 style={{ margin: "5px auto" }}>
                    Want to book your slot?
                  </h4>
                </div>
                <button
                  style={{
                    backgroundColor: "#5084b7",
                    color: "#ffffff",
                    width: "75%",
                    border: "solid 1px #464646",
                    height: "30px",
                    margin: "20px auto 0px auto",
                    display: "none",
                  }}
                  onClick={() => {
                    history.push(`/page/booking/${appid}/${couponCode}`);
                  }}
                >
                  Book Now
                </button>
              </div>
              <h3
                style={{
                  height: "30px",
                  padding: "10px 20px",
                  width: "90%",
                  fontSize: "10px",
                }}
              ></h3>
            </Paper>
          </Modal>
        </div>
      ) : (
        <div className="text-center mt-2 mb-lg-2" style="width: 18rem;">
          <div className="card-header">
            <h2 className="text-dark font-weight-bold">Thank you for Offer</h2>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  error: state.offers.error,
  success: state.offers.success,
  loading: state.offers.loading,
  offer: state.offers.offer,
  getOffer: state.offers.fetchOffer,
});

const mapActionsToProps = {
  Offer,
  fetchOfferData,
};
//export default connect(mapStateToProps, mapActionsToProps)(OfferDetails);
export default injectIntl(
  connect(mapStateToProps, mapActionsToProps)(OfferDetails)
);
