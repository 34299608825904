import * as requestFromServer from "./couponCrud";
import { couponSlice } from "./couponSlice";
import { getErrorMessage, getErrorMessageDetails } from "../../../utils";

const { actions } = couponSlice;
export const Coupon = (formData, appId, couponCode) => (dispatch) => {
  return new Promise(function(resolve, reject) {
  dispatch(actions.startCall());
  return requestFromServer
    .CreateCoupon(formData, appId)
    .then((response) => {
      const couponResponse = response.data;
      dispatch(actions.couponData({ couponResponse }));
    resolve(response)
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
     reject(error)
    });
  });
};

export const fetchCoupon = () => (dispatch) => {
  console.log("FETCHING...");
  dispatch(actions.startCall());
  return requestFromServer
    .getCoupon()
    .then((response) => {
      const couponResponse = response.data;
      dispatch(actions.fetchCouponData({ couponResponse }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const fetchAllCoupons = (token) => (dispatch) => {
  console.log("FETCHING...");
  dispatch(actions.startCall());
  return requestFromServer
    .getAllCoupons(token)
    .then((response) => {
      const couponResponse = response.data;
      dispatch(actions.totalCoupons({ couponResponse }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};

export const fetchAppData = () => (dispatch) => {
  console.log("FETCHING...");
  dispatch(actions.startCall());
  return requestFromServer
    .getAppDetails()
    .then((response) => {
      const couponResponse = response.data;
      console.log("appResponse", couponResponse);
      dispatch(actions.fetchAppDetails({ couponResponse }));
    })
    .catch((error) => {
      const errorMessage = getErrorMessage(error);
      dispatch(actions.catchError({ error: errorMessage }));
    });
};
