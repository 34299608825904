import { Grid, Modal, Box, Paper, Divider, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import BookingChart from "../../../../components/charts/BookingChart";
import CouponsChart from "../../../../components/charts/CouponsChart";
import AssignedCouponChart from "../../../../components/charts/AssignedCouponChart";
import AvailedCouponChart from "../../../../components/charts/AvailedCouponChart";
import NotAvailedCouponChart from "../../../../components/charts/NotAvailedCouponChart";
import AssignedCoupons from "../../../../components/RetailerApplications/AssignedCoupons";
import AvailedCoupons from "../../../../components/RetailerApplications/AvailedCoupons";
import ReachImpressionChart from "../../../../components/charts/ReachImpressionChart";
import YouTubeChart from "../../../../components/charts/YouTubeChart";
import PieChartConversions from "../../../../components/charts/PieChartConversions"
import CouponsList from "../../../../components/RetailerApplications/CouponsList";
import { connect } from "react-redux";
import numeral from "numeral";
import { fetchYouTubeInsightsGraph } from "../../../Applications/_redux/organizationsActions"
import AssignedButNotAvailedCoupons from "../../../../components/RetailerApplications/AssignedButNotAvailedCoupons";

/* ========= styles ========= */
import classes from "./Dashboard.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faYoutube, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { makeStyles } from '@material-ui/styles';

//Tooltip
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 17,
    minWidth: 100
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} placement='right' />;
}
const Dashboard = ({ appDetail, ...props }) => {
  const [table, setTable] = useState(false);
  const [availed, setAvailed] = useState(false);
  const [notAvailed, setNotAvailed] = useState(false);
  const [coupons, setCoupons] = useState(false);

  const handleTable = () => setTable(true);
  const handleAvailed = () => setAvailed(true);
  const handleNotAvailed = () => setNotAvailed(true);
  const handleCoupons = () =>
    setCoupons(true);
  console.log("youtubeinsights", props.youtubeinsights)
  // const total = appDetail.reduce((prev,next) => prev + next.impressions,0);

  // console.log(total);
  //   const total=appDetail.reduce((total,currentItem) =>  total = total + currentItem.impressions , 0 );
  // console.log("total",total)

  //Instagram
  // const InstagramSaves = appDetail?.instagramData?.actions.filter((item) => item.action_type == "onsite_conversion.post_save")
  //   .map((item) => item.value);
  // const InstagramShares = appDetail?.instagramData?.actions.filter((item) => item.action_type == "post")
  //   .map((item) => item.value);
  // const InstagramLikes = appDetail?.instagramData?.actions.filter((item) => item.action_type == "post_reaction")
  //   .map((item) => item.value);
  // const InstagramConversions = appDetail?.instagramData?.actions.filter((item) => item.action_type == "onsite_conversion.lead_grouped")
  //   .map((item) => item.value);

  const InstagramSaves = numeral(appDetail?.instagramData?.InstagramSavePost ).format((parseInt(appDetail?.instagramData?.InstagramSavePost) < 1000) ? "0.a" : "0.0a");
  const InstagramShares = numeral(appDetail?.instagramData?.InstagramSharePost).format((parseInt(appDetail?.instagramData?.InstagramSharePost) < 1000) ? "0.a" : "0.0a");
  const InstagramLikes = numeral(appDetail?.instagramData?.InstagramLikePost).format((parseInt(appDetail?.instagramData?.InstagramLikePost) < 1000) ? "0.a" : "0.0a");
  const InstagramConversions = numeral(appDetail?.instagramData?.InstagramLeadPost).format((parseInt(appDetail?.instagramData?.InstagramLeadPost) < 1000) ? "0.a" : "0.0a");
  const InstagramComments = numeral(appDetail?.instagramData?.InstagramCommentPost).format((parseInt(appDetail?.instagramData?.InstagramCommentPost) < 1000) ? "0.a" : "0.0a");
  const InstagramImpression = numeral(appDetail?.instagramData?.InstagramImpressions).format((parseInt(appDetail?.instagramData?.InstagramImpressions) < 1000) ? "0.a" : "0.0a");
  const InstagramReach = numeral(appDetail?.instagramData?.InstagramReach).format((parseInt(appDetail?.instagramData?.InstagramReach) < 1000) ? "0.a" : "0.0a");

  //Youtube 
  const YouTubeImpressions = numeral(appDetail?.youtubeData?.impressions).format((parseInt(appDetail?.youtubeData?.impressions) < 1000) ? "0.a" : "0.0a");
  const YouTubeShares = numeral(appDetail?.youtubeData?.shares).format((parseInt(appDetail?.youtubeData?.shares) < 1000) ? "0.a" : "0.0a");
 // const YOUTUBE_SAVE = numeral(appDetail?.YOUTUBE_SAVE).format((parseInt(appDetail?.YOUTUBE_SAVE) < 1000) ? "0.a" : "0.0a");
  const YoutubeLikes = numeral(appDetail?.youtubeData?.likes).format((parseInt(appDetail?.youtubeData?.likes) < 1000) ? "0.a" : "0.0a");
  const YoutubeComments = numeral(appDetail?.youtubeData?.comments).format((parseInt(appDetail?.youtubeData?.comments) < 1000) ? "0.a" : "0.0a");
  const YoutubeViews = numeral(appDetail?.youtubeData?.views).format((parseInt(appDetail?.youtubeData?.views) < 1000) ? "0.a" : "0.0a");
  const YoutubeClicks = numeral(appDetail?.youtubeData?.clicks).format((parseInt(appDetail?.youtubeData?.clicks) < 1000) ? "0.a" : "0.0a");


  //Facebook
  const FacebookSaves = numeral(appDetail?.facebookData?.FacebookSavePost).format((parseInt(appDetail?.facebookData?.FacebookSavePost) < 1000) ? "0.a" : "0.0a");
  const FacebookLikes = numeral(appDetail?.facebookData?.FacebookLikePost).format((parseInt(appDetail?.facebookData?.FacebookLikePost) < 1000) ? "0.a" : "0.0a");
  const FacebookShares = numeral(appDetail?.facebookData?.FacebookSharePost).format((parseInt(appDetail?.facebookData?.FacebookSharePost) < 1000) ? "0.a" : "0.0a");
  const FacebookConversions = numeral(appDetail?.facebookData?.FacebookLeadPost).format((parseInt(appDetail?.facebookData?.FacebookLeadPost) < 1000) ? "0.a" : "0.0a");
  const FacebookComments = numeral(appDetail?.facebookData?.FacebookCommentPost).format((parseInt(appDetail?.facebookData?.FacebookCommentPost) < 1000) ? "0.a" : "0.0a");
  const FacebookImpressions = numeral(appDetail?.facebookData?.FacebookImpressions).format((parseInt(appDetail?.facebookData?.FacebookImpressions) < 1000) ? "0.a" : "0.0a");
  const FacebookReach = numeral(appDetail?.facebookData?.FacebookReach).format((parseInt(appDetail?.facebookData?.FacebookReach) < 1000) ? "0.a" : "0.0a");

  //TOTAL 
  const TotalImpressions = numeral(appDetail?.facebookData?.FacebookImpressions + appDetail?.instagramData?.InstagramImpressions + appDetail?.youtubeData?.impressions)
  .format((parseInt(appDetail?.facebookData?.FacebookImpressions + appDetail?.instagramData?.InstagramImpressions + appDetail?.youtubeData?.impressions) < 1000) ? "0.a" : "0.0a");
  const TotalReach = numeral(appDetail?.facebookData?.FacebookReach + appDetail?.instagramData?.InstagramReach)
  .format((parseInt(appDetail?.facebookData?.FacebookReach + appDetail?.instagramData?.InstagramReach) < 1000) ? "0.a" : "0.0a");
  const TotalLeads = numeral(appDetail?.facebookData?.FacebookLeadPost + appDetail?.instagramData?.InstagramLeadPost)
  .format((parseInt(appDetail?.facebookData?.FacebookLeadPost + appDetail?.instagramData?.InstagramLeadPost) < 1000) ? "0.a" : "0.0a");
  const TotalShares = numeral(appDetail?.facebookData?.FacebookSharePost + appDetail?.instagramData?.InstagramSharePost + appDetail?.youtubeData?.shares)
  .format((parseInt(appDetail?.facebookData?.FacebookSharePost + appDetail?.instagramData?.InstagramSharePost + appDetail?.youtubeData?.shares) < 1000) ? "0.a" : "0.0a");
  const TotalSaves = numeral(appDetail?.facebookData?.FacebookSavePost + appDetail?.instagramData?.InstagramSavePost)
  .format((parseInt(appDetail?.facebookData?.FacebookSavePost + appDetail?.instagramData?.InstagramSavePost) < 1000) ? "0.a" : "0.0a");
  const TotalComments = numeral(appDetail?.facebookData?.FacebookCommentPost + appDetail?.instagramData?.InstagramCommentPost + appDetail?.youtubeData?.comments)
  .format((parseInt(appDetail?.facebookData?.FacebookCommentPost + appDetail?.instagramData?.InstagramCommentPost + appDetail?.youtubeData?.comments) < 1000) ? "0.a" : "0.0a");
  const TotalLikes = numeral(appDetail?.facebookData?.FacebookLikePost + appDetail?.instagramData?.InstagramLikePost + appDetail?.youtubeData?.likes)
  .format((parseInt(appDetail?.facebookData?.FacebookLikePost + appDetail?.instagramData?.InstagramLikePost + appDetail?.youtubeData?.likes) < 1000) ? "0.a" : "0.0a");

  console.log("likes", TotalLikes)
  //Original data
  const Instagramsaves = appDetail?.instagramData?.InstagramSavePost;
  const Instagramshares = appDetail?.instagramData?.InstagramSharePost;
  const Instagramlikes = appDetail?.instagramData?.InstagramLikePost;
  const Instagramconversions = appDetail?.instagramData?.InstagramLeadPost;
  const Instagramcomments = appDetail?.instagramData?.InstagramCommentPost;
  const Instagramimpression = appDetail?.instagramData?.InstagramImpressions;
  const Instagramreach = appDetail?.instagramData?.InstagramReach;


  //Facebook
  const Facebooksaves = appDetail?.facebookData?.FacebookSavePost;
  const Facebooklikes = appDetail?.facebookData?.FacebookLikePost;
  const Facebookshares = appDetail?.facebookData?.FacebookSharePost;
  const Facebookconversions = appDetail?.facebookData?.FacebookLeadPost;
  const Facebookcomments = appDetail?.facebookData?.FacebookCommentPost;
  const Facebookimpressions = appDetail?.facebookData?.FacebookImpressions;
  const Facebookreach = appDetail?.facebookData?.FacebookReach;


  //YouTube
  const YouTubeimpressions = appDetail?.youtubeData?.impressions;
  const YouTubeshares = appDetail?.youtubeData?.shares;
  //const YouTubesaves = appDetail?.YOUTUBE_SAVE;
  const YouTubelikes = appDetail?.youtubeData?.likes;
  const Youtubecomments = appDetail?.youtubeData?.comments;
  const Youtubeviews = appDetail?.youtubeData?.views;
  const Youtubeclicks = appDetail?.youtubeData?.clicks;

  //TOTAL DATA
  const Totalimpressions = appDetail?.facebookData?.FacebookImpressions + appDetail?.instagramData?.InstagramImpressions + appDetail?.youtubeData?.impressions;
  const Totalreach = appDetail?.facebookData?.FacebookReach + appDetail?.instagramData?.InstagramReach;
  const Totalleads = appDetail?.facebookData?.FacebookLeadPost + appDetail?.instagramData?.InstagramLeadPost;
  const Totalshares = appDetail?.facebookData?.FacebookSharePost + appDetail?.instagramData?.InstagramSharePost + appDetail?.youtubeData?.shares;
  const Totalsaves = appDetail?.facebookData?.FacebookSavePost + appDetail?.instagramData?.InstagramSavePost;
  const Totalcomments = appDetail?.facebookData?.FacebookCommentPost + appDetail?.instagramData?.InstagramCommentPost + appDetail?.youtubeData?.comments;
  const Totallikes = appDetail?.facebookData?.FacebookLikePost + appDetail?.instagramData?.InstagramLikePost + appDetail?.youtubeData?.likes;
  console.log("totallikes", Totalimpressions)
  return (
    <>
      {/* <Modal
        className="d-flex justify-content-center align-items-center w-100 h-500"
        open={table}
        onClose={() => setTable(false)}
     >
        <Paper>
          <div>
            <div className="text-primary font-weight-bold font-size-h2 ml-10 mt-4 d-inline text-center w-100">
              Assigned CouponsList
            </div>
            <div className={classes.div_modal}
               >
              <AssignedCoupons />
            </div>
          </div>
        </Paper>
  </Modal>*/}

      <Modal
        className="d-flex justify-content-center align-items-center w-100 h-500"
        open={availed}
        onClose={() => setAvailed(false)}
      >
        <Paper>
          <div>
            <div className="text-primary font-weight-bold font-size-h2 ml-10 mt-4 d-inline text-center w-100">
              Redeemed CouponsList
            </div>
            <div className={classes.div_modal}>
              <AvailedCoupons />
            </div>
          </div>
        </Paper>
      </Modal>

      {/* <Modal
        className="d-flex justify-content-center align-items-center w-100 h-500"
        open={notAvailed}
        onClose={() => setNotAvailed(false)}
      >
        <Paper>
          <div>
            <div className="text-primary font-weight-bold font-size-h2 ml-10 mt-4 d-inline text-center w-100">
              NotAvailed CouponsList
            </div>
            <div className={classes.div_modal}>
              <AssignedButNotAvailedCoupons />
            </div>
          </div>
        </Paper>
</Modal>*/}


      <Modal
        className="d-flex justify-content-center align-items-center w-100 h-500"
        open={coupons}
        onClose={() => setCoupons(false)}
      >
        <Paper>
          <div>
            <div className="text-primary font-weight-bold font-size-h2 ml-10 mt-4 d-inline text-center w-100">
              CouponsList
            </div>
            <div className={classes.div_modal}>
              <CouponsList />
            </div>
          </div>
        </Paper>
      </Modal>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4} sm={12} >
          <Grid item className={classes.impression}>
            <div
              className="col px-6 py-6 mr-7 d-inline-block">
              <div className="d-flex">
                <div className={classes.h4}>
                  Impressions
                </div>
              </div>

              <div className={classes.h2} title={Totalimpressions}>
                {TotalImpressions ? TotalImpressions : 0}
              </div>

              <div className="d-flex justify-content-between mt-4 ">
                <div className="d-flex w-100">
                  <span><img src="/media/socialicons/facebook.png" height="20" /></span>

                  <p className={classes.h5} title={Facebookimpressions}>{FacebookImpressions ? FacebookImpressions : 0}</p>

                </div>

                <div className="d-flex w-100">
                  <span><img src="/media/socialicons/instagram.png" height="20" /></span>
                  <p className={classes.h5} title={Instagramimpression}>{InstagramImpression ? InstagramImpression : 0}</p>
                </div>
                <div className="d-flex w-100">
                  <span> <img src="/media/socialicons/youtube.png" height="20" /></span>
                  <p className={classes.h5} title={YouTubeimpressions}>{YouTubeImpressions ? YouTubeImpressions : 0}</p>
                </div>

              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} sm={12}>
          <Grid item className={classes.reach}>
            <div
              className="col px-6 py-6 mr-7 d-inline-block">
              <div className="d-flex">
                <div className={classes.h4}>
                  Reach
                </div>
              </div>
              <div className={classes.h2} title={Totalreach}>
                {TotalReach ? TotalReach : 0}
              </div>
              <div className="d-flex justify-content-between mt-4">
                <div className="d-flex w-100">
                  <span><img src="/media/socialicons/facebook.png" height="20" /></span>
                  <p className={classes.h5} title={Facebookreach}>{FacebookReach ? FacebookReach : 0}</p>
                </div>

                <div className="d-flex w-100">
                  <span><img src="/media/socialicons/instagram.png" height="20" /></span>
                  <p className={classes.h5} title={Instagramreach}> {InstagramReach ? InstagramReach : 0}</p>
                </div>
                <div className="d-flex w-100">

                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} sm={12} >
          <Grid item className={classes.leads}>
            <div
              className="col px-6 py-6 mr-7 d-inline-block">
              <div className="d-flex ">
                <div className={classes.h4}>
                  Leads
                </div>
              </div>
              <div className={classes.h2} title={Totalleads}>
                {TotalLeads ? (
                  TotalLeads
                ) : (
                  0
                )}
              </div>
              <div className="d-flex justify-content-between mt-4">
                <div className="d-flex w-100">
                  <span><img src="/media/socialicons/facebook.png" height="20" /></span>
                  <p className={classes.h5} title={Facebookconversions}>{FacebookConversions ? FacebookConversions : 0}</p>
                </div>

                <div className="d-flex w-100">
                  <span><img src="/media/socialicons/instagram.png" height="20" /></span>
                  <p className={classes.h5} title={Instagramconversions}>{InstagramConversions ? InstagramConversions : 0}</p>
                </div>
                <div className="d-flex w-100">

                </div>
              </div>
            </div>
          </Grid>

        </Grid>

        {/*  <Grid item xs={12} md={3} sm={12} >
          <Grid item className="mt-2">
            <div
              className="col bg-secondary px-6 py-6 rounded-xl mr-7 d-inline-block">
              <div className="d-flex">

                <div className="text-primary font-weight-bold text-uppercase font-size-h4 d-inline w-100">
                  Total Conversions
                </div>
              </div>
              <div className="text-primary font-weight-bold font-size-h2">
                {appDetail.TOTAL_LEADS ? (
                  appDetail.TOTAL_LEADS
                ) : (
                  <h2>0</h2>
                )}
              </div>
              <div className="d-flex justify-content-between mt-4">
                <span><FontAwesomeIcon icon={faFacebook} size="1x" /></span>
                <h6>2435</h6>
                <span><FontAwesomeIcon icon={faYoutube} size="1x" /></span><h6>2435</h6>
                <span><FontAwesomeIcon icon={faInstagram} size="1x" /></span><h6>2435</h6>
              </div>
            </div>
                </Grid>
        </Grid>*/}

        <Grid item xs={12} md={4} sm={12} className={classes.card_container}>
          <Grid container spacing={1} className={classes.card_info}>


            <Grid item xs={12} md={12} sm={12} style={{ height: "170px" }} className={classes.grid_inner}>
              <div className="d-flex flex-row  ">
                <div className="col-md-6 col-xs-12">
                  <div className={classes.h6}>Share</div>
                  <div className={classes.h1} title={Totalshares}>
                    {TotalShares ? (
                      TotalShares
                    ) : (
                      0
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex w-100">  <span className={classes.low_opacity}><img src="/media/socialicons/facebook.png" height="20" /></span>
                      <p className={classes.h5} title={Facebookshares}>
                        {FacebookShares ? (
                          FacebookShares
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex w-100"><span className={classes.youtube}><img src="/media/socialicons/youtube.png" height="20" />
                    </span>
                      <p className={classes.h5} title={YouTubeshares}>
                        {YouTubeShares ? (
                          YouTubeShares
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex w-100"> <span className={classes.low_opacity}><img src="/media/socialicons/instagram.png" height="20" /></span>
                      <p className={classes.h5} title={Instagramshares}>
                        {InstagramShares ? (
                          InstagramShares
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={12} sm={12} style={{ borderTop: "1px solid grey", height: "170px" }} className={classes.grid_inner}>
              <div className="d-flex flex-row">
                <div className="col-md-6 col-xs-12">
                  <div className={classes.h6}>Save</div>
                  <div className={classes.h1} title={Totalsaves}>
                    {TotalSaves ? (
                      TotalSaves
                    ) : (
                      0
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex w-100"> <span className={classes.low_opacity}><img src="/media/socialicons/facebook.png" height="20" /></span>
                      <p className={classes.h5} title={Facebooksaves}>
                        {FacebookSaves ? (
                          FacebookSaves
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex w-100"><span className={classes.youtube}><img src="/media/socialicons/youtube.png" height="20" /></span>
                      <p className={classes.h5} title={"0"}>
                        0
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex w-100"> <span className={classes.low_opacity}><img src="/media/socialicons/instagram.png" height="20" /></span>
                      <p className={classes.h5} title={Instagramsaves}>
                        {InstagramSaves ? (
                          InstagramSaves
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={12} sm={12} style={{ borderTop: "1px solid grey", height: "170px" }} className={classes.grid_inner}>
              <div className="d-flex flex-row">
                <div className="col-md-6 col-xs-12">
                  <div className={classes.h6}>Likes</div>
                  <div className={classes.h1} title={Totallikes} >
                    {TotalLikes ? (
                      TotalLikes
                    ) : (
                      0
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex w-100"><span className={classes.low_opacity}><img src="/media/socialicons/facebook.png" height="20" /></span>
                      <p className={classes.h5} title={Facebooklikes}>
                        {FacebookLikes ? (
                          FacebookLikes
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex w-100"><span className={classes.youtube}><img src="/media/socialicons/youtube.png" height="20" /></span>
                      <p className={classes.h5} title={YouTubelikes}>
                        {YoutubeLikes ? (
                          YoutubeLikes
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex w-100"><span className={classes.low_opacity}><img src="/media/socialicons/instagram.png" height="20" /></span>
                      <p className={classes.h5} title={Instagramlikes}>
                        {InstagramLikes ? (
                          InstagramLikes
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={12} sm={12} style={{ borderTop: "1px solid grey", height: "170px" }} className={classes.grid_inner}>
              <div className="d-flex flex-row">
                <div className="col-md-6 col-xs-12">
                  <div className={classes.h6}>Comments</div>
                  <div className={classes.h1} title={Totalcomments}>
                    {TotalComments ? (
                      TotalComments
                    ) : (
                      0
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex w-100"><span className={classes.low_opacity}><img src="/media/socialicons/facebook.png" height="20" /></span>
                      <p className={classes.h5} title={Facebookcomments}>
                        {FacebookComments ? (
                          FacebookComments
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex w-100"><span className={classes.youtube}><img src="/media/socialicons/youtube.png" height="20" /></span>
                      <p className={classes.h5} title={Youtubecomments}>
                        {YoutubeComments ? (
                          YoutubeComments
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex w-100"><span className={classes.low_opacity}><img src="/media/socialicons/instagram.png" height="20" /></span>
                      <p className={classes.h5} title={Instagramcomments}>
                        {InstagramComments ? (
                          InstagramComments
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={12} sm={12} style={{ borderTop: "1px solid grey", height: "170px" }} className={classes.grid_inner}>
              <div className={classes.grid_row} >
                <div className="col-md-6 col-xs-12 ">
                  <div className={classes.h6}>Views</div>
                  <div className={classes.h1} title={Youtubeviews}>
                    {YoutubeViews ? (
                      YoutubeViews
                    ) : (
                      0
                    )}
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-between">
                  <div className="d-flex w-100">
                    <span className={classes.youtube}><img src="/media/socialicons/youtube.png" height="20" /></span>
                    <p className={classes.h5} title={Youtubeviews}>{YoutubeViews ? (
                      YoutubeViews
                    ) : (
                      0
                    )}</p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={12} sm={12} style={{ borderTop: "1px solid grey", height: "170px" }} className={classes.grid_inner}>
              <div className="d-flex flex-row">
                <div className="col-md-6 col-xs-12 ">
                  <div className={classes.h6}>Clicks</div>
                  <div className={classes.h1} title={Youtubeclicks}>
                    {YoutubeClicks ? (
                      YoutubeClicks
                    ) : (
                      0
                    )}
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-between">
                  <div className="d-flex w-100"><span className={classes.low_opacity}><img src="/media/socialicons/youtube.png" height="20" /></span>
                    <p className={classes.h5} title={Youtubeclicks}>
                      {YoutubeClicks ? (
                        YoutubeClicks
                      ) : (
                        0
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Grid>

          </Grid>
        </Grid>
        <Grid item xs={12} md={8} sm={12} className={classes.graph_container}>
          <Grid
            className={classes.graph_coloumn}
          >
            <Card className="mt-2">
              <CardHeader title="Facebook and Instagram Graph" className={classes.card} />
              <CardBody>
                <ReachImpressionChart /></CardBody>
            </Card>
            <Card>
              <CardHeader title="YouTube Graph" />
              <CardBody>

                <YouTubeChart />

              </CardBody>
            </Card>

          </Grid>
        </Grid>


        {/* <Grid item xs={12} md={6} sm={12} style={{ borderTop: "1px solid grey" }}>

            <div onClick={handleAvailed}
              className="col bg-light-info px-6 py-8 rounded-xl mr-7 d-inline-block dashCard">
              <div className="d-flex">
              
                 
                <div className="text-primary font-weight-bold font-size-h6 ml-3 mt-2 d-inline text-center w-100">
                  Coupons Redeemed
                </div>
              </div>
              <div className="text-primary font-weight-bold font-size-h2 mt-2 text-center">
                {appDetail.availCoupons ? appDetail.availCoupons : <h4>0</h4>}
              </div>
            </div>
            <div className="mt-2">
              <AvailedCouponChart />
            </div>
          </Grid>
        
         <Grid item xs={12} md={6} sm={12} style={{ borderTop: "1px solid grey" }}>

          <div onClick={handleCoupons}
            className="col bg-success px-6 py-10 rounded-xl mr-7 d-inline-block dashCard">
            <div className="d-flex">

              <div className="text-primary font-weight-bold font-size-h6 ml-3 mt-2 d-inline text-center w-100">
                Total Number Of Coupons
              </div>
            </div>
            <div className="text-primary font-weight-bold font-size-h2 mt-2 text-center">
              {appDetail.couponCodes ? appDetail.couponCodes : <h4>0</h4>}
            </div>
          </div>
          <div className="mt-2">
            <CouponsChart />
          </div>

        </Grid>*/}
      </Grid>

    </>
  );
}
const mapStateToProps = (state) => ({
  error: state.applications.error,
  success: state.applications.success,
  loading: state.applications.loading,
  youtubeinsights: state.applications.youtubeinsights,

  auth: state.auth

})

const mapActionsToProps = {
  fetchYouTubeInsightsGraph,

};
export default connect(mapStateToProps, mapActionsToProps)(Dashboard);
