import React, { useMemo, useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, Field } from "formik";
import {
  Input,
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { FilePlus as AddFileIcon } from "react-feather";
import { useSelector } from "react-redux";
import {
  fetchUpdateRetailers,
  RemoveImage,
} from "../Applications/_redux/retailerAction";
import { connect } from "react-redux";
import { DeleteImageFile } from "./_redux/retailerCrud";

const EditRetailerPage = ({ auth, EditRetailersData, ...props }) => {
  const [logoSelected, setLogoSelected] = useState(null);
  const [isLogoPicked, setLogoPicked] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);
  const [isImagePicked, setImagePicked] = useState(false);

  const EditRetailers = useSelector(
    (state) => state.retailer.EditRetailersData
  );
  console.log("edit",EditRetailers)

  const [retailerLogo, setRetailerLogo] = useState(null);

  const [retailerLogoPrev, setRetailerLogoPrev] = useState(
    EditRetailers?.retailer.logo.props.src
  );

  const [retailerImage, setRetailerImage] = useState(null);

  const [retailerImagePrev, setRetailerImagePrev] = useState(
    EditRetailers?.retailer.retailerImage.props.src
  );

  const [loading, setLoading] = useState(false);
  const appId = EditRetailers?.retailer.id;
  console.log("appid", appId);

  const handleMedia = (event) => {
    if (event) {
      setLogoSelected(event.target.files[0]);
      setLogoPicked(true);
    }
  };
  const handleDeleteLogo = () => {
    setRetailerLogoPrev(null);
    if (auth) {
      props.RemoveImage(auth.authToken, EditRetailers?.retailer.logo.props.src,appId);
    }
  };

  const handleDeleteImagefile = () => {
    setRetailerImagePrev(null);
    if (auth) {
      props.RemoveImage(
        auth.authToken,
        EditRetailers?.retailer?.retailerImage?.props.src,appId
      );
    }
  };

  const handleRetailerImage = (event) => {
    const image = event.target.files[0];
    setRetailerImage(image);
    setRetailerImagePrev(URL.createObjectURL(image));
  };

  const handleRetailerLogo = (event) => {
    const image = event.target.files[0];
    setRetailerLogo(image);
    setRetailerLogoPrev(URL.createObjectURL(image));
  };
  //const handleDeleteLogo = () => {
  //  setRetailerLogo(null);
  //  setRetailerLogoPrev(null);
  //};
  const onCancelLogo = () => {
    setRetailerLogo(null);
    setRetailerLogoPrev(null);
  };
  const onCancelImage = () => {
    setRetailerImage(null);
    setRetailerImagePrev(null);
  };
  const handleImageMedia = (event) => {
    if (event) {
      setImageSelected(event.target.files[0]);
      setImagePicked(true);
    }
  };
  const onRemove = () => {
    setLogoPicked(false);
    setLogoSelected(null);
  };

  const onImageRemove = () => {
    setImagePicked(false);
    setImageSelected(null);
  };
  const handleRetailer = (values) => {
    const formData = new FormData();
    formData.append("name", values["name"]);
    formData.append("logo", retailerLogo);
    formData.append("retailerImage", retailerImage);
    formData.append("email", values["email"]);
    formData.append("mobile", values["mobile"]);

    props
      .fetchUpdateRetailers(auth.authToken, formData, appId)
      
      .then((response) => {
       
        toast.success("Retailer updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((err) => {
        const errorDetails = err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong";
        toast.error(errorDetails, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      });
  };
  return (
    <Card>
      <CardHeader title="Edit Retailer">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: EditRetailers.name,
            mobile: EditRetailers.mobile,
            email: EditRetailers.email,
          }}
          validationSchema={Yup.object().shape({
            // name: Yup.string().required("Employee code is required."),
            // email: Yup.string()
            //   .email("Invalid email address format")
            //   .required("Email is required"),
            // mobile: Yup.string().required("phoneNumber is required")
          })}
          onSubmit={handleRetailer}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            isSubmitting,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="col-lg-12">
                    <div classN ame="form-group">
                      <Field
                        name="name"
                        component={Input}
                        placeholder="Name"
                        label="Name"
                        value={values.name}
                        onChange={handleChange}
                        withFeedbackLabel={false}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-7">
                    <div className="form-group">
                      <Field
                        name="email"
                        component={Input}
                        placeholder="Email"
                        label="Email"
                        value={values.email}
                        onChange={handleChange}
                        withFeedbackLabel={false}
                        required
                      />
                    </div>
                  </div>

                  <Box mt={2}>
                    <Typography>
                      Retailer Logo <span style={{ color: "red" }}>*</span>
                    </Typography>
                    {!retailerLogoPrev ? (
                      <Field
                        input
                        label="Image"
                        type="file"
                        className=" mt-2 border border-secondary rounded p-2 w-100"
                        name="logo"
                        onChange={handleRetailerLogo}
                      />
                    ) : (
                      <div className="d-flex flex-row  border border-secondary rounded p-2 w-100 ">
                        <Typography className="d-flex align-items-center"></Typography>
                        <img src={retailerLogoPrev} height={50} width={100} />
                        {!retailerLogo ? (
                          <Button className="ml-6" onClick={handleDeleteLogo}>
                            Remove
                          </Button>
                        ) : (
                          <Button className="ml-6" onClick={onCancelLogo}>
                            Cancel
                          </Button>
                        )}
                      </div>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {/*  <Box>
                      <Typography>Open Time <span style={{color: 'red'}}>*</span></Typography>
                      <DatePicker
                        selected={startDate}
                        className="od__textField datepicker"
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </Box>
                    <Box mt={2}>
                      <Typography>Close Time <span style={{color: 'red'}}>*</span></Typography>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        className="od__textField datepicker"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </Box>*/}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <Field
                        name="mobile"
                        component={Input}
                        placeholder="Mobile"
                        label="Mobile"
                        value={values.mobile}
                        onChange={handleChange}
                        withFeedbackLabel={false}
                        maxLength="10"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12" style={{ visibility: "hidden" }}>
                    <div className="form-group">
                      <Field
                        name="appId"
                        component={Input}
                        placeholder="AppID"
                        label="AppID"
                        value={values.appId}
                        onChange={handleChange}
                        withFeedbackLabel={false}
                      />
                    </div>
                  </div>

                  <Box mt={3}>
                    <Typography>
                      Retailer Image <span style={{ color: "red" }}>*</span>
                    </Typography>
                    {!retailerImagePrev ? (
                      <Field
                        input
                        label="Image"
                        type="file"
                        className=" mt-2 border border-secondary rounded p-2 w-100"
                        name="retailerImage"
                        onChange={handleRetailerImage}
                      />
                    ) : (
                      <div className="d-flex flex-row  border border-secondary rounded p-2 w-100 ">
                        <Typography className="d-flex align-items-center"></Typography>
                        <img src={retailerImagePrev} height={50} width={100} />
                        {!retailerImage ? (
                          <Button
                            className="ml-6"
                            onClick={handleDeleteImagefile}
                          >
                            Remove
                          </Button>
                        ) : (
                          <Button className="ml-6" onClick={onCancelImage}>
                            Cancel
                          </Button>
                        )}
                      </div>
                    )}
                  </Box>
                  <Box mt={3}>
                    <Button
                      color="primary"
                      disabled={loading}
                      type="submit"
                      variant="contained"
                      endIcon={
                        loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          ""
                        )
                      }
                    >
                      Edit Retailer
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};
const mapStateToProps = (state) => ({
  error: state.apps.error,
  success: state.apps.success,
  loading: state.apps.loading,
  updatedata: state.retailer.retailerEditData,
  auth: state.auth,
});
const mapActionsToProps = {
  fetchUpdateRetailers,
  RemoveImage,
};

export default connect(mapStateToProps, mapActionsToProps)(EditRetailerPage);