import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Input,
  CardHeaderToolbar,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import * as Yup from "yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

/* ========= redux,router ========= */
import {
  EditApps,
  FetchAllCategories,
  FetchAllPages,
  RemoveImage,
} from "../../../pages/AppList/redux/appAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

/* ========= style for form ========= */
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { Formik, Field, FieldArray, Form } from "formik";
import { toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
} from "@material-ui/core";

const AppEditPage = ({ auth, appEditData, ...props }) => {
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const filter = createFilterOptions();
  const history = useHistory();
  const editData = useSelector((state) => state.apps.appEditData);
  const [category, setCategory] = useState(editData?.categories);
  const [landingPage, setLandingPage] = useState(editData?.landingPage);
  const [startTime, setStartTime] = useState(editData?.openTime);
  const [endTime, setEndTime] = useState(editData?.closeTime);
  const [appLogo, setAppLogo] = useState(null);
  const [appLogoPrev, setAppLogoPrev] = useState(editData?.image1);
  const [bannerImage, setBannerImage] = useState(null);
  const [bannerImgPrev, setBannerImgPrev] = useState(editData?.image2);
  const [additionalImage, setAdditionalImage] = useState(null);
  const [additionalImgPrev, setAdditionalImgPrev] = useState(editData?.image3);
  const appId = editData.appId;
  const icons = editData && JSON.parse(editData?.socialMediaLinks);
  useEffect(() => {
    if (auth) {
      props.FetchAllCategories(auth.authToken);
    }
  }, [auth]);

  const handleDeleteLogo = () => {
    setAppLogoPrev(null);
    if (auth) {
      props.RemoveImage(auth.authToken, editData.image1,appId);
    }
  };

  const handleAppLogo = (event) => {
    const image = event.target.files[0];
    setAppLogo(image);
    setAppLogoPrev(URL.createObjectURL(image));
  };

  const onCancelLogo = () => {
    setAppLogo(null);
    setAppLogoPrev(null);
  };

  const handleBannerImage = (event) => {
    const image = event.target.files[0];
    setBannerImage(image);
    setBannerImgPrev(URL.createObjectURL(image));
  };

  const handleDeleteBanner = (img) => {
    const deleteImage = { ...editData };
    deleteImage[`${img}`] = null;
    setBannerImgPrev(null);
    setBannerImage(null);
    if (auth) {
      props.RemoveImage(auth.authToken, editData.image2,appId);
    }
  };

  const onCancelBanner = () => {
    setBannerImage(null);
    setBannerImgPrev(null);
  };

  const handleAdditionalImage = (event) => {
    const image = event.target.files[0];
    setAdditionalImage(image);
    setAdditionalImgPrev(URL.createObjectURL(image));
  };

  const handleDeleteBanner2 = (img) => {
    const deleteImage = { ...editData };
    deleteImage[`${img}`] = null;
    setAdditionalImgPrev(null);
    setAdditionalImage(null);
    if (auth) {
      props.RemoveImage(auth.authToken, editData.image3,appId);
    }
  };

  const onCancelBanner2 = () => {
    setAdditionalImage(null);
    setAdditionalImgPrev(null);
  };

  const handleSelect = (eventKey) => {
    setCategory(eventKey);
    const data = props.allCategories.filter(
      (item) => item.categoryName === eventKey
    );
    const categoryId = data[0]?.categoryId;
    props.FetchAllPages(auth.authToken, categoryId);
  };

  const handleSelectedPage = (e) => {
    setLandingPage(e);
  };

  const handleApp = (values) => {
    const formData = new FormData();
    formData.append("appName", values["appName"]);
    formData.append("tagLine", values["tagLine"]);
    formData.append("address", values["address"]);
    formData.append("description", values["description"]);
    formData.append("fbCampaignId", values["fbCampaignId"]);
    formData.append("youtubeCampaignId", values["youtubeCampaignId"]);
    formData.append("categories", category);
    formData.append("landingPage", landingPage);
    formData.append("openTime", startTime);
    formData.append("closeTime", endTime);
    formData.append("image1", appLogo);
    formData.append("image2", bannerImage);
    formData.append("image3", additionalImage);
    formData.append("socialMediaLinks", JSON.stringify(values.Links));
    props
      .EditApps(auth.authToken, formData, appId)
    
      .then((response) => {
        toast.success("App updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((err) => {
        const errorDetails = err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong";
        toast.error(errorDetails, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      });
  };

  return (
    <Card>
      <CardHeader title="Edit App">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Formik
          enableReinitialize={true}
          initialValues={{
            appName: editData.appName,
            tagLine: editData.tagLine,
            description: editData.description,
            address: editData.address,
            startTime: editData.openTime,
            endTime: editData.closeTime,
            fbCampaignId: editData.fbCampaignId,
            youtubeCampaignId: editData.youtubeCampaignId,
            Links: icons ? icons : [],
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={handleApp}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            setFieldValue,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={10}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box>
                    <Field
                      name="appName"
                      component={Input}
                      placeholder="AppName"
                      label="AppName"
                      onChange={handleChange}
                      value={values.appName}
                      withFeedbackLabel={false}
                    />
                  </Box>

                  <Box mt={2}>
                    <Field
                      name="tagLine"
                      component={Input}
                      placeholder="Tagline"
                      label="Tagline"
                      onChange={handleChange}
                      value={values.tagLine}
                      withFeedbackLabel={false}
                    />
                  </Box>
                  <Box mt={2}>
                    <Field
                      name="description"
                      component={Input}
                      placeholder="Description"
                      label="Description"
                      onChange={handleChange}
                      value={values.description}
                      withFeedbackLabel={false}
                    />
                  </Box>
                  <Box mt={2}>
                    <Field
                      name="address"
                      component={Input}
                      placeholder="Address"
                      label="Address"
                      onChange={handleChange}
                      value={values.address}
                      withFeedbackLabel={false}
                    />
                  </Box>

                  <Box mt={2}>
                    <Typography>App Logo</Typography>
                    {!appLogoPrev ? (
                      <Field
                        input
                        label="Image"
                        type="file"
                        className=" mt-2 border border-secondary rounded p-2 w-100"
                        name="image1"
                        onChange={handleAppLogo}
                      />
                    ) : (
                      <div className="d-flex flex-row  border border-secondary rounded p-2 w-100 ">
                        <Typography className="d-flex align-items-center"></Typography>
                        <img src={appLogoPrev} height={50} width={100} />
                        {!appLogo ? (
                          <Button className="ml-6" onClick={handleDeleteLogo}>
                            Remove
                          </Button>
                        ) : (
                          <Button className="ml-6" onClick={onCancelLogo}>
                            Cancel
                          </Button>
                        )}
                      </div>
                    )}
                  </Box>

                  <Box mt={2}>
                    <Typography>Banner Image</Typography>
                    {!bannerImgPrev ? (
                      <Field
                        input
                        label="Image"
                        type="file"
                        name="image2"
                        className=" mt-2 border border-secondary rounded p-2 w-100"
                        onChange={handleBannerImage}
                      />
                    ) : (
                      <div className="d-flex flex-row  border border-secondary rounded p-2 w-100 ">
                        <Typography className="d-flex align-items-center"></Typography>
                        <img src={bannerImgPrev} height={50} width={100} />
                        {!bannerImage ? (
                          <Button className="ml-6" onClick={handleDeleteBanner}>
                            Remove
                          </Button>
                        ) : (
                          <Button className="ml-6" onClick={onCancelBanner}>
                            Cancel
                          </Button>
                        )}
                      </div>
                    )}
                  </Box>
                  <Box mt={2}>
                    <Typography>Additional Image</Typography>
                    {!additionalImage ? (
                      <Field
                        input
                        label="Image"
                        type="file"
                        name="image3"
                        className=" mt-2 border border-secondary rounded  p-2 w-100"
                        onChange={handleAdditionalImage}
                      />
                    ) : (
                      <div className="d-flex flex-row  border border-secondary rounded p-2 w-100 ">
                        <Typography className="d-flex align-items-center"></Typography>
                        <img src={additionalImgPrev} height={50} width={100} />
                        {!additionalImage ? (
                          <Button
                            className="ml-6"
                            onClick={() => handleDeleteBanner2("image3")}
                          >
                            Remove
                          </Button>
                        ) : (
                          <Button className="ml-6" onClick={onCancelBanner2}>
                            Cancel
                          </Button>
                        )}
                      </div>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography>Select Open and CloseTime</Typography>
                  <div className="d-flex justify-content-between">
                    <div className="w-100 mr-2">
                      <DatePicker
                        value={startTime}
                        className="mb-2  mt-2 border border-secondary rounded p-3 w-100"
                        onChange={(date) => setStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </div>
                    <div className="w-100">
                      <DatePicker
                        value={endTime}
                        onChange={(date) => setEndTime(date)}
                        // className="od__textField datepicker"
                        className="mb-2  mt-2 border border-secondary rounded p-3 w-100"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </div>
                  </div>

                  {/* <Box mt={2}>
                  <Typography>Choose Category<span style={{ color: 'red' }}>*</span></Typography>
                  <Autocomplete
                    id="adset-select"
                    sx={{ width: 540 }}
                    options={props.allCategories}
                    autoHighlight
                    name="category"

                    getOptionLabel={(option) => option.categoryName}
                    onChange={(e, val) => {
                      setFieldValue("category", val.categoryId);
                      props.FetchAllPages(auth.authToken, val.categoryId)
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ mr: 4, flexShrink: 0 }} {...props}>

                        <h1>{option.name}</h1>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose Category"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off', // disable autocomplete and autofill
                        }}
                      />

                    )}
                    filterOptions={(renderOption, params) => {
                      const filtered = filter(renderOption, params);
                      console.log("checking..", filtered, renderOption, params)
                      return filtered;
                    }}
                  />


                </Box>
                  */}

                  {/* <Box mt={2}>
                  <Typography>Choose LandingPage<span style={{ color: 'red' }}>*</span></Typography>
                  <Autocomplete
                    id="adset-select"
                    sx={{ width: 540 }}
                    options={props.allPages}
                    name="landingPage"
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    onChange={(e, val) => {
                      setFieldValue("landingPage", val.name);

                    }}

                    renderOption={(props, option) => (
                      <Box component="li"  {...props}>
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose LandingPage"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                          // disable autocomplete and autofill
                        }}
                      />

                    )}
                    filterOptions={(renderOption, params) => {
                      const filtered = filter(renderOption, params);
                      console.log("checking..", filtered, renderOption, params)
                      return filtered;
                    }}
                  />


                  </Box>*/}
                  {/*<Box mt={1}>
                    <Field
                      name="fbCampaignId"
                      component={Input}
                      placeholder="fbCampaignId"
                      label="FbCampaignId"
                      onChange={handleChange}
                      value={values.fbCampaignId}
                      withFeedbackLabel={false}
                    />
                </Box>*/}

                
                  <Box mt={2}>
                    <Typography>Select App Category</Typography>
                    <DropdownButton
                      fullWidth={true}
                      alignLeft
                      title={category}
                      id="dropdown-basic-button"
                      className="d-inline mt-6 mr-5 w-100"
                      variant="secondary"
                      onSelect={handleSelect}
                      onClick={() => {
                        setLandingPage("Choose LandingPage");
                      }}
                    >
                      {props.allCategories ? (
                        props.allCategories.map((item, index) => (
                          <Dropdown.Item eventKey={item.categoryName}>
                            {item.categoryName}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <div>No Options</div>
                      )}
                    </DropdownButton>

                    {category && (
                      <DropdownButton
                        title={landingPage}
                        id="dropdown-basic-button"
                        // align={{ lg: 'end' }}
                        className="d-inline mt-6 mr-5 w-100"
                        variant="secondary"
                        onSelect={handleSelectedPage}
                      >
                        {props.allPages ? (
                          props.allPages.map((item, index) => (
                            <Dropdown.Item eventKey={item.name}>
                              {item.name}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <div>Loading ...</div>
                        )}
                      </DropdownButton>
                    )}
                  </Box>

                  <Box mt={2}>
                    <FieldArray
                      name="Links"
                      render={(arrayHelpers) => (
                        <div>
                          <Grid item>
                            <Typography>
                              Add Icons<span style={{ color: "red" }}></span>
                            </Typography>
                          </Grid>

                          {/* Add a new empty item at the end of the list */}

                          {values.Links.length > 0 &&
                            values.Links.map((value, index) => (
                              <div key={index} style={{ display: "flex" }}>
                                <Select
                                  displayEmpty
                                  className=" mb-2 mr-2  mt-2 border border-secondary rounded w-50 "
                                  name={`Links[${index}].type`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={value.type}
                                >
                                  <MenuItem value={"facebook"}>
                                    Facebook
                                  </MenuItem>
                                  <MenuItem value={"instagram"}>
                                    Instagram
                                  </MenuItem>
                                  <MenuItem value={"youtube"}>YouTube</MenuItem>
                                </Select>

                                <Field
                                  type="text"
                                  name={`Links[${index}].url`}
                                  className="mb-2 mt-2 border border-secondary rounded w-100"
                                  placeholder="URL"
                                  onChange={handleChange}
                                  value={value.url}
                                />
                                <button
                                  type="button"
                                  className="mr-1 ml-6 mt-4 mb-4"
                                  onClick={() => arrayHelpers.remove(index)} // remove a file from the list
                                >
                                  -
                                </button>
                              </div>
                            ))}
                          <button
                            type="button"
                            variant="contained"
                            className="btn btn-secondary mt-2 "
                            onClick={() => arrayHelpers.push("")}
                          >
                            Add SocialIcons
                          </button>
                        </div>
                      )}
                    />
                  </Box>

                  <Box mt={2}>
                    <Button
                      color="primary"
                      disabled={loading}
                      type="submit"
                      variant="contained"
                      endIcon={
                        loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          ""
                        )
                      }
                    >
                      Edit App
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  error: state.apps.error,
  success: state.apps.success,
  loading: state.apps.loading,
  allAppsEditData: state.apps.allAppsEditData,
  allCategories: state.apps.allCategories,
  allPages: state.apps.allPages,
  auth: state.auth,
});

const mapActionsToProps = {
  EditApps,
  FetchAllCategories,
  FetchAllPages,
  RemoveImage,
};

export default connect(mapStateToProps, mapActionsToProps)(AppEditPage);