import React, { useRef, useState, useEffect } from "react";
import { Modal, Paper, Input, Box, TextField, Button, Typography, } from "@material-ui/core";
import { QrReader } from "react-qr-reader";
import { ScanCoupon } from "../../pages/Offer/redux/offerAction";
import { Formik, Field } from 'formik';
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import classes from "./Coupon.module.scss";

// Due to browser implementations the camera can only be accessed over https or localhost.
// In Firefox a prompt will be shown to the user asking which camera to use, so facingMode will not affect it.
// On IOS 11 it is only supported on Safari and not on Chrome or Firefox due to Apple making the API not available to 3th party browsers.

const CouponScanner = ({ auth, ...props }) => {
  console.log("PROPS: " + JSON.stringify(props));
  const [qrReaderModal, setqrReaderModal] = useState(false);
  const [qrScanResultModal, setqrScanResultModal] = useState(false);
  const [qrFormModal, setqrFormModal] = useState(false);
  const [selected, setSelected] = useState("environment");
  const [appId, setAppId] = useState(null);
  const [couponCode, setCouponCode] = useState(null);
  const token = auth ? auth.authToken : null;
  // QR Code Scanner
  const onScanFile = () => {
    console.log("open");
    setqrReaderModal(true);
  };
  const qrRef = useRef(null);
  const [scanResultWebCam, setScanResultWebCam] = useState("");

  const handleErrorWebCam = (error) => {
    //alert(JSON.stringify(error));
    console.log("CAM ERROR");
  };
  const handleScanWebCam = (result) => {
    if (result && typeof result != "undefined") {
      console.log(JSON.stringify(result.text));
      handleScannedCoupon(JSON.parse(result.text));
      const data = JSON.parse(result.text);
      const { appId, couponcode } = data;
      setAppId(appId);
      setCouponCode(couponcode);
      console.log("parsedata", JSON.parse(result.text));
    }
  }; // End of QR Code Scanner

  useEffect(() => {
    if (props.redeemCoupon) {
      console.log("USEEFFECT: " + props.redeemCoupon);
    }
  }, [props.redeemCoupon]);

  //Coupon Scanned Data push to API
  const handleScannedCoupon = () => {
    setqrReaderModal(false);
    setqrFormModal(true);
  }
  const handleDetailsCoupon = (values) => {
    console.log("valuesss", values)
    setqrFormModal(false);
    setqrScanResultModal(true);
    setScanResultWebCam("Validating QR Code...");
    if (token) {
      const rowData = {
        "name": values.name,
        "email": values.email,
        "mobile": values.mobile,
        "couponCode": couponCode,
        "appId": appId
      }
      try {
        props
          .ScanCoupon(token, rowData)
          .then((res) => {
            console.log(props.redeemCoupon.message);
            setScanResultWebCam(props.redeemCoupon.message);
          })
          .catch((err) => {
            console.log(err.response);
            const errorDetails = err?.response?.data?.message
              ? err.response.data.message
              : "Something went wrong";
            setScanResultWebCam(errorDetails);
            // toast.error(errorDetails, {
            // setqrReaderModal(false);
            // setqrScanResultModal(true);
          });
      } catch (error) {
        alert("Error: " + JSON.stringify(error));
      }
    }
  };


  //Ends here

  return (
    <>
      <button
        type="button"
        className="btn btn-primary ml-2"
        data-toggle="modal"
        data-target="#exampleModalLong"
        onClick={onScanFile}
      >
        <i className="ki ki-menu icon-md mr-2"></i>
        Scan Coupon
      </button>

      <Modal
        className="d-flex justify-content-center align-items-center w-300 h-300"
        open={qrReaderModal}
        onClose={() => setqrReaderModal(false)}
      >
        <Paper>
          <div className={classes.coupon}>
            <div className=" text-primary font-weight-bold font-size-h2 ml-3 mt-2 d-inline text-center w-100">
              Scan QR to Redeem Coupon<br />
            </div>
            <div>
              <select style={{ borderStyle: "none", display:"none", marginLeft: "10px" }} onChange={(e) => {const value=e.target.value;  return setSelected(value)}}>
                <option value={"environment"}>Back Camera</option>
                <option value={"user"}>Front Camera</option>
              </select></div>

            <div
              style={{
                boxSizing: "border-box",
                zIndex: "1600",
                border: "20px solid rgba(0, 0, 0, 0.3)",
                boxShadow: "rgb(255 0 0 / 50%) 0px 0px 0px 5px inset",
                width: "400px !important",
                height: "70%",
              }}
            >
              <div>
              </div>

              <QrReader
                onError={handleErrorWebCam}
                onScan={handleScanWebCam}
                onResult={handleScanWebCam}
                legacyMode={true}
                constraints={{ facingMode: `${selected}` }
                }

              />

            </div>
          </div>
        </Paper>
      </Modal>

      <Modal
        className="d-flex justify-content-center align-items-center w-300 h-300"
        open={qrFormModal}
        onClose={() => setqrFormModal(false)}
      >
        <Paper>
          <div style={{ width: "400px", textAlign: "center", padding: "50px" }}>
            <div className="text-primary font-weight-bold font-size-h2 ml-3 mt-3 d-inline text-center w-100">
              Coupon scanned: {`${couponCode}`}<br />
              <h4>Get Customer details</h4>
            </div>

            <Formik
              enableReinitialize={true}
              initialValues={{
                name: "",
                email: "",
                mobile: "",
              }}

              onSubmit={handleDetailsCoupon}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                setFieldValue,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mt={2}>
                    <Field
                      style={{
                        borderColor: "light",
                        stroke: "#dcf3fc",
                        borderRadius: "10px",
                        borderWidth: "20",
                        borderStyle: "solid",
                        display: "block",
                        width: "100%",
                        padding: "10px 12px"

                      }}
                      name="name"
                      placeholder="Name"
                      label="Name"
                      onChange={handleChange}
                      value={values.name}
                      required

                    />

                  </Box>
                  <Box mt={2}>
                    <Field
                      style={{
                        borderColor: "light",
                        stroke: "#dcf3fc",
                        borderRadius: "10px",
                        borderWidth: "20",
                        borderStyle: "solid",
                        display: "block",
                        width: "100%",
                        padding: "10px 12px"

                      }}
                      name="email"
                      placeholder="Email"
                      label="Email"
                      onChange={handleChange}
                      value={values.email}
                      required

                    />
                  </Box>
                  <Box mt={2}>
                    <Field
                      style={{
                        borderColor: "light",
                        stroke: "#dcf3fc",
                        borderRadius: "10px",
                        borderWidth: "20",
                        borderStyle: "solid",
                        display: "block",
                        width: "100%",
                        padding: "10px 12px"

                      }}
                      name="mobile"
                      placeholder="Mobile"
                      label="Mobile"
                      onChange={handleChange}
                      value={values.mobile}
                      // withFeedbackLabel={false}
                      required
                    />
                  </Box>
                  <Box mt={2}>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      Redeem Coupon
                    </Button></Box>
                </form>
              )}
            </Formik>
          </div>
        </Paper>
      </Modal>
      <Modal
        className="d-flex justify-content-center align-items-center w-300 h-300"
        open={qrScanResultModal}
        onClose={() => setqrScanResultModal(false)}
      >
        <Paper>
          <div style={{ width: "400px", textAlign: "center", padding: "20px" }}>
            <div className="text-primary font-weight-bold font-size-h2 ml-3 mt-2 d-inline text-center w-100">
              Scan Result
            </div>

            <h3
              style={{
                minHeight: "100px",
                padding: "10px 20px",
                width: "90%",
                fontSize: "12px",
                margin: "auto",
              }}
            >
              {/* {scanResultWebCam} */}
              {props?.redeemCoupon?.message}
            </h3>
            <br />
            <button
              type="button"
              className="btn btn-primary ml-2"
              data-toggle="modal"
              data-target="#exampleModalLong"
              onClick={onScanFile}
            >
              <i className="ki ki-menu icon-md mr-2"></i>
              Scan Coupon
            </button>
          </div>
        </Paper>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  redeemCoupon: state.offers.redeemCoupon,
  auth: state.auth,
});

const mapActionsToProps = {
  ScanCoupon,
};

export default connect(mapStateToProps, mapActionsToProps)(CouponScanner);
